import { Lager } from "Models/Lager";
import { useState } from "react";
import ArtikelListe from "./ArtikelListe";
import LagerListe from "./LagerListe";

type LagerverwaltungProps = {
    firmaId: number;
    lagerChanged: (lager: Lager | undefined) => void;
};
export default function Lagerverwaltung(props: Readonly<LagerverwaltungProps>) {
    const { firmaId } = props;
    const [selectedLager, setSelectedLager] = useState<Lager | undefined>(undefined);

    const triggerReload = () => {
        console.log("Artikelliste hat sich geändert");
    };

    return (
        <>
            <h4>Lager</h4>
            <LagerListe firmaId={firmaId} lagerChanged={(l) => setSelectedLager(l)}></LagerListe>
            <h4>Artikel</h4>
            {selectedLager === undefined && <>Kein Lager ausgewählt</>}
            {selectedLager && <ArtikelListe lager={selectedLager} onChange={triggerReload} />}
        </>
    );
}
