import { AusgangsLagerPicker } from "applications/components/AusgangsLagerPicker";
import { AppContextRuecklogistik } from "context/AppContextRuecklogistik";

import { Lager } from "Models/Lager";
import { useContext } from "react";

/**
 * Sets the Ausgangslager for the Rücklogistik in teh AppContext
 * @returns
 */
export default function AusgangsLagerPickerRuecklogistik() {
    const { ausgangslager, setAusgangslager } = useContext(AppContextRuecklogistik);

    const lagerChanged = (lager: Lager) => {
        setAusgangslager(lager);
    };

    return <AusgangsLagerPicker selected={ausgangslager} onSelected={(lager) => lagerChanged(lager)} />;
}
