type EnumValueDisplayProps = {
    value: string;
};
export default function EnumValueDisplay(props: Readonly<EnumValueDisplayProps>) {
    const { value } = props;

    if (value.length < 2) return value;

    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}
