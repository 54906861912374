import { Box } from "@mui/material";
import SquareIcon from "@mui/icons-material/Square";
import EnumValueDisplay from "./EnumValueDisplay";

type LieferungsTypProps = {
    typ: string;
    farbe: string;
};
export default function LieferungsTyp(props: Readonly<LieferungsTypProps>) {
    const { typ, farbe } = props;
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <SquareIcon fontSize="small" sx={{ stroke: "#000", strokeWidth: 2, paddingRight: 1 }} htmlColor={farbe} />
            <EnumValueDisplay value={typ} />
        </Box>
    );
}
