import { Lager } from "./Lager";
import LieferscheinItem from "./LieferscheinItem";

type Lieferschein = {
    id: string;
    lieferscheinNummer: string;
    typ: LieferscheinType;
    farbe: string;
    status: LieferscheinStatus;
    gebinde: GebindeForm;
    ausgangslager: Lager;
    eingangslager: Lager;
    transitlager: string;
    zielort?: string;
    createdAt: Date;
    orderedAt?: Date;
    items: LieferscheinItem[];
};

export default Lieferschein;

export enum LieferscheinType {
    BEFUND = "BEFUND",
    ELEKTRONISCH = "ELEKTRONISCH",
    MECHANISCH = "MECHANISCH",
    UNBEKANNT = "UNBEKANNT",
    WANDLER = "WANDLER",
    SONSTIGES = "SONSTIGES", // Deckel, etc
}

export enum LieferscheinStatus {
    OFFEN = "OFFEN",
    VERSANDBEREIT = "VERSANDBEREIT",
    BEAUFTRAGT = "BEAUFTRAGT",
    ABGESCHLOSSEN = "ABGESCHLOSSEN",
}

export enum GebindeForm {
    GITTERBOX = "GITTERBOX",
    SAFETYBOX = "SAFETYBOX",
    SKYBOX = "SKYBOX",
    EUROPALETTE = "EUROPALETTE",
    HALBPALETTE = "HALBPALETTE",
    TRANSPORTKISTE_GRUEN = "TRANSPORTKISTE_GRUEN",
    TRANSPORTKISTE_ROT = "TRANSPORTKISTE_ROT",
}
