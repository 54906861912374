import { Alert, LinearProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import LieferscheinDruckenView from "./LieferscheinDruckenView";
import "./LieferscheinDrucken.css";
import useGetById from "applications/hooks/useGetById";
import { LieferscheinKommissionierung } from "Models/LieferscheinKommissionierung";
import { useEffect } from "react";
import ErrorDisplay from "applications/components/ErrorDisplay";

export default function LieferscheinDrucken() {
    const { id } = useParams();

    const { data, error, isLoading, loadById } = useGetById<LieferscheinKommissionierung>("angelo/logistik/lieferschein");

    useEffect(() => {
        if (id) {
            loadById(id);
        }
    }, []);

    return (
        <div className="printContent">
            {error && <ErrorDisplay title="Fehler" error={"Ein Fehler ist aufgetreten: " + error} />}
            {isLoading && <LinearProgress />}
            {data && <LieferscheinDruckenView lieferschein={data} />}
        </div>
    );
}
