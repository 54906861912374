import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import CustomGridToolbar from "layout/CustomGridToolbar";
import { Alert, LinearProgress } from "@mui/material";
import { Artikel } from "Models/Artikel";
import useWarenkorb from "../Warenkorb/useWarenkorb";
import { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "context/AppContext";
import { config } from "config";
import AuthenticatedImg from "layout/AuthenticatedImage";
import WarenkorbLagerPicker from "./WarenkorbLagerPicker";
import { Lager } from "Models/Lager";
import useAuthentication from "authentication/useAuthentication";
import useGetDynamicPath from "applications/hooks/useGetDynamicPath";
import { ArtikelWithPuffer } from "Models/ArtikelWithPuffer";
import AddCartItem from "./AddCartItem";
import { CartItem } from "Models/CartItem";

export default function ArtikelPage() {
    const { cartLager } = useContext(AppContext);

    const [theLager, setTheLager] = useState<Lager | undefined>(cartLager);

    useEffect(() => {
        if (cartLager?.id !== theLager?.id) {
            // only change lager, if changed
            setTheLager(cartLager);
        }
    }, [cartLager, theLager]);

    return (
        <div className="contentDiv">
            <WarenkorbLagerPicker onSelect={(l) => {}} />
            {theLager && <ArtikelList selectedLager={theLager} />}
        </div>
    );
}

const ARTIKEL_URL = "/angelo/firma/{firma}/lager/{lager}/artikelzuweisung";

type ArtikelListProps = {
    selectedLager: Lager;
};
export function ArtikelList(props: Readonly<ArtikelListProps>) {
    const { selectedLager } = props;
    const { firmaIdAnforderer } = useAuthentication();
    const { setCartItemCount } = useContext(AppContext);
    const [entries, setEntries] = useState<CartItem[]>([]);

    const { data, error, isLoading, load } = useGetDynamicPath<ArtikelWithPuffer>(ARTIKEL_URL);

    const { isLoading: isLoadingWk, warenkorb, triggerReload } = useWarenkorb();

    useEffect(() => {
        if (warenkorb) {
            let en = warenkorb.bestellpositionen ?? [];
            setEntries(en);
            setCartItemCount(en.length);
        }
    }, [warenkorb, setEntries, setCartItemCount]);

    useEffect(() => {
        triggerReload();
        load([
            { key: "firma", value: firmaIdAnforderer },
            { key: "lager", value: selectedLager.id.toString() },
        ]);
    }, [selectedLager, firmaIdAnforderer]);

    const bestellbareMengen = useMemo(() => {
        let mengePerArtikel: { [id: string]: number } = {};

        if (selectedLager && data) {
            data.forEach((a) => {
                //only montageauftraege of the currently selected lager is relevant
                let matchingAuftrag = a.montageAuftraege.find((a) => a.lager.id === selectedLager.id);
                let auftragsMenge = matchingAuftrag?.anzahl ?? 0;

                //only bestand of the currently selected lager is relevant
                let matchingLagerbestand = a.lagerBestaende.find((a) => a.lager.id === selectedLager.id);
                let lagerMenge = matchingLagerbestand?.anzahl ?? 0;

                // calculate the allowed amount of this article
                let allowedAmount = auftragsMenge - lagerMenge + a.puffer;
                let bestellbar = Math.max(0, allowedAmount);
                mengePerArtikel[a.id] = bestellbar;
            });
        }

        return mengePerArtikel;
    }, [data, selectedLager]);

    const columns: GridColDef<Artikel>[] = [
        {
            field: " ",
            type: "custom",
            width: 120,
            renderCell: (p) => {
                let artikelInWarenkorb = entries.find((e) => e.artikel.id === p.row.id);
                let availableOrderAmount = bestellbareMengen[p.id];

                return <AddCartItem artikel={p.row} onChanged={(amount) => triggerReload()} max={availableOrderAmount} amount={artikelInWarenkorb?.anzahl ?? 0} />;
            },
        },
        {
            field: "imageData",
            headerName: "Bild",
            flex: 2,
            renderCell: (p) => {
                let article = p.row;
                if (!article.hasImage) {
                    return <></>;
                }
                return <AuthenticatedImg alt={"artikel " + p.row.id} src={config.BASE_URL + "angelo/artikel/" + p.row.id + "/image"} inList={true} artikelName={p.row.name} />;
            },
        },
        { field: "funktionsklasse", headerName: "Funktionsklasse", flex: 1.5 },
        { field: "name", headerName: "Klartext", flex: 2 },
        { field: "bauklasse", headerName: "Bauklasse", flex: 1.5 },
        { field: "artikelnummer", headerName: "Artikelnummer", flex: 1.5 },
        {
            field: "montageAuftraege",
            headerName: "Montageaufträge",
            flex: 2.5,
            renderCell: (p) => {
                let auftraege = p.row.montageAuftraege;
                let montageAuftrag = auftraege.find((a) => a.lager.id === selectedLager.id);
                if (!montageAuftrag) {
                    return <>0</>;
                } else {
                    return <>{montageAuftrag.anzahl}</>;
                }
            },
        },
        {
            field: "lagerBestaende",
            headerName: "Lagerbestand",
            flex: 2.5,
            renderCell: (p) => {
                let bestandInLager = p.row.lagerBestaende.find((b) => b.lager.id === selectedLager.id);
                if (!bestandInLager) {
                    return <>0</>;
                } else {
                    return <>{bestandInLager.anzahl}</>;
                }
            },
        },
        {
            field: "erlaubteMenge",
            headerName: "Erlaubte Bestellmenge",
            type: "custom",
            flex: 2,
            valueGetter: (p, row) => {
                return bestellbareMengen[row.id];
            },
        },
        { field: "puffer", headerName: "Pufferbestand", flex: 2 },
        { field: "bemerkungen", headerName: "Bemerkung", flex: 3 },
    ];

    return (
        <div className="contentDiv">
            {error !== "" && (
                <Alert severity="error">
                    <span>{"Ein Fehler ist aufgetreten! " + error}</span>
                </Alert>
            )}

            {selectedLager && data && (
                <DataGridPro
                    sx={{
                        ".not-orderable": {
                            opacity: 0.5,
                        },
                    }}
                    density="compact"
                    loading={isLoading || isLoadingWk}
                    getRowId={(r) => r.id}
                    rows={data}
                    columns={columns}
                    pagination
                    slots={{
                        toolbar: CustomGridToolbar,
                        loadingOverlay: LinearProgress as any,
                    }}
                    getRowClassName={(p) => {
                        if (bestellbareMengen[p.id] <= 0) {
                            return "not-orderable";
                        }
                        return "";
                    }}
                />
            )}
        </div>
    );
}
