import { useEffect, useRef, useState } from "react";
import axios from "ApiClient";
import useOnScreen from "applications/hooks/useOnScreen";
import { CircularProgress } from "@mui/material";
import ArtikelImageDialog from "applications/administration/artikelManagement/ArtikelImageDialog";

export type AuthenticatedImgProps = {
    src: string;
    alt: string;
    inList: boolean;
    artikelName: string;
};

export default function AuthenticatedImg(props: Readonly<AuthenticatedImgProps>) {
    const { src, alt, inList, artikelName } = props;
    const [imgData, setImgData] = useState<string | ArrayBuffer | null>();
    const [showArtikelImageDialog, setShowArtikelImageDialog] = useState<boolean>(false);
    const [element, setElement] = useState<JSX.Element | null>(null);
    const imgContainerRef = useRef<HTMLDivElement | null>(null);
    const isVisible = useOnScreen(imgContainerRef);

    useEffect(() => {
        const retrieveImgData = async () => {
            try {
                let response = await axios.get(src, {
                    responseType: "blob",
                });

                if (response.status === 200) {
                    let reader = new window.FileReader();
                    reader.readAsDataURL(response.data);
                    reader.onloadend = function () {
                        let base64data = reader.result;
                        setImgData(base64data);
                    };
                }
            } catch (error) {
                console.error("Error fetching image:", error);
            }
        };
        if (isVisible && !imgData) {
            retrieveImgData();
        }
    }, [src, isVisible, imgData]);

    useEffect(() => {
        if (imgData) {
            let newElement = <img src={imgData?.toString()} alt={alt} height={inList ? "34px" : "100%"} />;
            setElement(newElement);
        } else {
            let newElement = <CircularProgress color="primary" size={34} />;
            setElement(newElement);
        }
    }, [imgData, alt, inList]);

    return (
        <div style={{ cursor: "pointer" }}>
            <div ref={imgContainerRef} onClick={() => setShowArtikelImageDialog(true)} role="button">
                {element}
            </div>
            {showArtikelImageDialog && imgData && <ArtikelImageDialog artikelName={artikelName} imageData={imgData} alt={alt} onClose={() => setShowArtikelImageDialog(false)} />}
        </div>
    );
}
