import { Box, LinearProgress, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import DateDisplay from "applications/components/DateDisplay";
import EnumValueDisplay from "applications/components/EnumValueDisplay";
import ErrorDisplay from "applications/components/ErrorDisplay";
import LieferungsTyp from "applications/components/LieferungsTyp";
import useGetById from "applications/hooks/useGetById";
import Lieferschein, { LieferscheinStatus } from "Models/Lieferschein";
import LieferscheinItem from "Models/LieferscheinItem";
import { useEffect } from "react";
import { SelectLieferscheinStatus } from "./SelectLieferscheinStatus";

type LieferscheinDetailsProps = {
    id?: string;
    onChanged: () => void;
};
export default function LieferscheinDetails(props: Readonly<LieferscheinDetailsProps>) {
    const { id, onChanged } = props;
    const allowedBearbeitungsStatus = [LieferscheinStatus.OFFEN, LieferscheinStatus.VERSANDBEREIT];
    const { data, error, isLoading, loadById } = useGetById<Lieferschein>("angelo/ruecklogistik/lieferscheine");

    const columns: GridColDef<LieferscheinItem>[] = [
        { field: "serialNummer", headerName: "Seriennummer", width: 150 },
        { field: "equipmentNummer", headerName: "Equipmentnummer", width: 150 },
        { field: "funktionsklasse", headerName: "Funktionsklasse", width: 150 },
        { field: "name", headerName: "Bezeichnung", width: 200 },
        { field: "artikelNummer", headerName: "Artikel", width: 150 },
        { field: "anzahl", headerName: "Anzahl", width: 150 },
    ];

    useEffect(() => {
        if (id) {
            loadById(id);
        }
    }, [id, loadById]);

    return (
        <>
            <Box>
                {isLoading && <LinearProgress />}
                {error && <ErrorDisplay title="Fehler beim Laden des Lieferescheins" error={error} />}
            </Box>
            {data && (
                <>
                    <strong>Lieferschein</strong>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell variant="head">Lieferscheinnummer</TableCell>
                                <TableCell>{data.lieferscheinNummer}</TableCell>
                                <TableCell variant="head">Status</TableCell>
                                <TableCell>
                                    {data && <SelectLieferscheinStatus lieferschein={data} allowedBearbeitungsStatus={allowedBearbeitungsStatus} onChange={() => onChanged()} />}
                                </TableCell>
                                <TableCell variant="head">Bestellt am</TableCell>
                                <TableCell>{data.orderedAt ? <DateDisplay date={data.orderedAt} /> : ""}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Ausgangslager</TableCell>
                                <TableCell>{data.ausgangslager.aNummer}</TableCell>
                                <TableCell variant="head">Eingangslager</TableCell>
                                <TableCell>{data.eingangslager.nummer}</TableCell>
                                <TableCell variant="head">Transitlager</TableCell>
                                <TableCell>{data.transitlager}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Gebinde</TableCell>
                                <TableCell>
                                    <EnumValueDisplay value={data.gebinde} />
                                </TableCell>
                                <TableCell variant="head">Typ</TableCell>
                                <TableCell>
                                    <LieferungsTyp typ={data.typ} farbe={data.farbe} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Zielort</TableCell>
                                <TableCell>{data.zielort}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Box>
                        <strong>Geräte auf dem Lieferschein</strong>
                        <DataGridPro columns={columns} rows={data.items} density="compact" isRowSelectable={() => false} />
                    </Box>
                </>
            )}
        </>
    );
}
