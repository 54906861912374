import { Box, Button } from "@mui/material";
import CreateLieferschein from "./CreateLieferschein";
import ListLieferscheine from "./ListLieferscheine";
import { useContext, useEffect, useState } from "react";
import LieferscheinDetails from "./LieferscheinDetails";
import { useNavigate, useParams } from "react-router-dom";
import AusgangsLagerPickerRuecklogistik from "../AusgangsLagerPickerRuecklogistik";
import { AppContextRuecklogistik } from "context/AppContextRuecklogistik";
import ReplayIcon from "@mui/icons-material/Replay";

export default function LieferscheineView() {
    const [selectedLieferschein, setSelectedLieferschein] = useState<string>();
    const { ausgangslager } = useContext(AppContextRuecklogistik);
    const [reloadCounter, setReloadCounter] = useState<number>(0);
    let { id } = useParams();

    let navigate = useNavigate();

    const selectLieferschein = (id: string) => {
        setSelectedLieferschein(id);
        navigate(`/ruecklogistik/lieferscheine/${id}`);
    };

    useEffect(() => {
        if (id) {
            setSelectedLieferschein(id);
        }
    }, [id]);

    return (
        <div className="contentDiv">
            <AusgangsLagerPickerRuecklogistik />
            <h1>Aktuelle Lieferscheine</h1>
            {!ausgangslager && <>Bitte Ausgangslager wählen.</>}
            {ausgangslager && (
                <>
                    <Box sx={{ display: "flex", width: 600, justifyContent: "space-between" }}>
                        <CreateLieferschein
                            ausgangslager={ausgangslager}
                            onChange={(ls) => {
                                setReloadCounter(reloadCounter + 1);
                            }}
                        />
                        <Button
                            onClick={() => {
                                setReloadCounter(reloadCounter + 1);
                            }}
                            variant="outlined"
                            size="small"
                            startIcon={<ReplayIcon fontSize="small" />}
                        >
                            Neu Laden
                        </Button>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ width: 610 }}>
                            <ListLieferscheine reloadCounter={reloadCounter} ausgangslager={ausgangslager} isHistory={false} onSelected={(id) => selectLieferschein(id)} />
                        </Box>
                        <Box sx={{ paddingLeft: 2, width: "100%" }}>
                            <LieferscheinDetails
                                id={selectedLieferschein}
                                onChanged={() => {
                                    setReloadCounter(reloadCounter + 1);
                                }}
                            />
                        </Box>
                    </Box>
                </>
            )}
        </div>
    );
}
