import { Box, Button, Grid2, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import useGetDynamicPath from "applications/hooks/useGetDynamicPath";
import { AppContextRuecklogistik } from "context/AppContextRuecklogistik";
import { Lager, LAGER_ZENTRALLAGER } from "Models/Lager";
import React, { useContext, useEffect, useState } from "react";
import AusgangsLagerPickerRuecklogistik from "../AusgangsLagerPickerRuecklogistik";
import GeraeteScanDialog from "./GeraeteScanDialog";
import SquareIcon from "@mui/icons-material/Square";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Sortierinformation from "Models/Sortierinformation";
import CreateLieferschein from "../lieferscheine/CreateLieferschein";
import Lieferschein, { GebindeForm, LieferscheinStatus } from "Models/Lieferschein";
import ErrorDisplay from "applications/components/ErrorDisplay";

export default function GeraetScannen() {
    const { ausgangslager } = useContext(AppContextRuecklogistik);
    const [serialnummer, setSerialnummer] = useState<string>("");
    const [serialnummerInput, setSerialnummerInput] = useState<string>("");

    const keyPress = (e: any) => {
        if (e.key === "Enter" && serialnummerInput !== "") {
            setSerialnummer(serialnummerInput);
        }
    };

    useEffect(() => {
        setSerialnummer("");
        setSerialnummerInput("");
    }, [ausgangslager]);

    return (
        <div className="contentDiv">
            <AusgangsLagerPickerRuecklogistik />
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ width: "70%" }}>
                    <h2>Gerät scannen</h2>
                    {ausgangslager && "Gerät scannen oder Seriennummer von Hand eingeben."}
                    {!ausgangslager && <Typography color={"error"}>Bitte Ausgangslager wählen!</Typography>}

                    {ausgangslager && (
                        <Grid2 container sx={{ paddingTop: 2 }} spacing={2}>
                            <Grid2 size={1.2}>Serialnummer:</Grid2>
                            <Grid2 size={3}>
                                <TextField
                                    disabled={!ausgangslager}
                                    fullWidth={true}
                                    size="small"
                                    variant="standard"
                                    placeholder="Serialnummer"
                                    value={serialnummerInput}
                                    focused={true}
                                    onChange={(e) => setSerialnummerInput(e.target.value)}
                                    onKeyDown={(e) => keyPress(e)}
                                    inputRef={(input) => input?.focus()}
                                />
                            </Grid2>
                            <Grid2 size={5}>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={() => {
                                        setSerialnummerInput("-");
                                        setSerialnummer("-");
                                    }}
                                >
                                    Serialnummer unlesbar
                                </Button>
                            </Grid2>
                            <Grid2 size={12}>{serialnummer && <GeraeteInformationen serialnummer={serialnummer} />}</Grid2>
                        </Grid2>
                    )}
                </Box>
                <Box>
                    <Box>
                        <h2>HINWEIS</h2>
                        Beispiele für existierende Geräte
                        <ul>
                            <li>
                                Mechanisch:
                                <ClickSerialNummer
                                    serial="EPPC0216335035"
                                    onClick={(sn) => {
                                        setSerialnummerInput(sn);
                                        setSerialnummer(sn);
                                    }}
                                />
                            </li>
                            <li>
                                Elektronisch:
                                <ClickSerialNummer
                                    serial="20617020"
                                    onClick={(sn) => {
                                        setSerialnummerInput(sn);
                                        setSerialnummer(sn);
                                    }}
                                />
                            </li>
                            <li>
                                Wandler:
                                <ClickSerialNummer
                                    serial="88001954" //88001946, 88001950, 22029607, 22029608, 22029609, 22029610
                                    onClick={(sn) => {
                                        setSerialnummerInput(sn);
                                        setSerialnummer(sn);
                                    }}
                                />
                            </li>
                            <li>
                                Befund:
                                <ClickSerialNummer
                                    serial="11068076"
                                    onClick={(sn) => {
                                        setSerialnummerInput(sn);
                                        setSerialnummer(sn);
                                    }}
                                />
                            </li>
                            <li>
                                2 Ergebnisse:
                                <ClickSerialNummer
                                    serial="97958618"
                                    onClick={(sn) => {
                                        setSerialnummerInput(sn);
                                        setSerialnummer(sn);
                                    }}
                                />
                            </li>
                            <li>Unbekannt: ?</li>
                        </ul>
                    </Box>
                </Box>
            </Box>
        </div>
    );
}

function ClickSerialNummer(props: Readonly<{ serial: string; onClick: (serial: string) => void }>) {
    const { serial, onClick } = props;

    return (
        <Button onClick={() => onClick(serial)} endIcon={<OpenInNewIcon fontSize="small" />}>
            {serial}
        </Button>
    );
}

const ROUTE = "angelo/ruecklogistik/lager/{lagerId}/sortierinformationen?serialnummer={serialnummer}";

type GeraeteInformationenProps = {
    serialnummer: string | undefined;
};
export function GeraeteInformationen(props: Readonly<GeraeteInformationenProps>) {
    const { serialnummer } = props;
    const { data, load, isLoading, error } = useGetDynamicPath<Sortierinformation>(ROUTE);
    const { ausgangslager } = useContext(AppContextRuecklogistik);
    const [lager, setLager] = useState<Lager | undefined>(ausgangslager);
    const [selectedGeraet, setSelectedGeraet] = useState<Sortierinformation | undefined>(undefined);
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const reload = () => {
        if (serialnummer && lager) {
            load([
                { key: "lagerId", value: lager.id.toString() },
                { key: "serialnummer", value: serialnummer },
            ]);
        }
    };

    useEffect(() => {
        if (serialnummer && lager) {
            reload();
        }
    }, [serialnummer, lager]);

    useEffect(() => {
        if (ausgangslager) {
            setLager(ausgangslager);
        }
    }, [ausgangslager]);

    useEffect(() => {
        if (data?.length === 1 && data[0].lieferschein) {
            setSelectedGeraet(data[0]);
        } else {
            setSelectedGeraet(undefined);
        }
    }, [data]);

    useEffect(() => {
        if (selectedGeraet) {
            setOpenDialog(true);
        } else {
            setOpenDialog(false);
        }
    }, [selectedGeraet]);

    return (
        <>
            {isLoading && <LinearProgress />}
            {error && <ErrorDisplay title="Fehler beim Scannen des Geräts" error={error} />}
            <h2>Sortierinformationen: {data?.length ?? 0}</h2>
            {serialnummer !== undefined && data && data.length > 0 && (
                <TableContainer>
                    <Table sx={{ width: "100%" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Equipmentnummer</TableCell>
                                <TableCell>Artikelnummer</TableCell>
                                <TableCell>Funktionsklasse</TableCell>
                                <TableCell>Lieferschein</TableCell>
                                <TableCell>Sortierschlüssel</TableCell>
                                <TableCell>Aktion</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((geraet) => {
                                let item = geraet.lieferscheinItem;
                                let lieferschein = geraet.lieferschein;
                                return (
                                    <React.Fragment key={item.equipmentNummer}>
                                        {!lieferschein && <RowNoLieferschein onCreated={() => reload()} item={geraet} />}
                                        {item.equipmentNummer && lieferschein && <RowItemAndLieferschein item={geraet} setSelectedGeraet={setSelectedGeraet} />}
                                        {!item.equipmentNummer && lieferschein && <RowNoEquipmentFound item={geraet} setSelectedGeraet={setSelectedGeraet} />}
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {selectedGeraet && <GeraeteScanDialog open={openDialog} sortingInfo={selectedGeraet} onClose={() => setSelectedGeraet(undefined)} />}
        </>
    );
}

type RowNoLieferscheinProps = {
    item: Sortierinformation;
    onCreated: (lieferschein: Lieferschein) => void;
};
function RowNoLieferschein(props: Readonly<RowNoLieferscheinProps>) {
    const { item, onCreated } = props;

    let device = item.lieferscheinItem;
    const { ausgangslager } = useContext(AppContextRuecklogistik);

    const input: Lieferschein = {
        status: LieferscheinStatus.OFFEN,
        typ: item.sortierSchluessel,
        zielort: "-",
        gebinde: GebindeForm.GITTERBOX,
        ausgangslager: ausgangslager,
        eingangslager: LAGER_ZENTRALLAGER,
    } as Lieferschein;

    return (
        <TableRow>
            <TableCell>{device.equipmentNummer ?? "Unbekanntes Gerät"}</TableCell>
            <TableCell>{device.artikelNummer}</TableCell>
            <TableCell>{device.funktionsklasse}</TableCell>
            <TableCell>
                <Typography color={"error"}>Kein Lieferschein vorhanden</Typography>
            </TableCell>
            <TableCell>{item.sortierSchluessel}</TableCell>
            <TableCell>
                {ausgangslager && (
                    <CreateLieferschein
                        ausgangslager={ausgangslager}
                        input={input}
                        onChange={() => {
                            onCreated(input);
                        }}
                    />
                )}
            </TableCell>
        </TableRow>
    );
}

type RowNoEquipmentFoundProps = {
    item: Sortierinformation;
    setSelectedGeraet: (geraet: Sortierinformation) => void;
};
export function RowNoEquipmentFound(props: Readonly<RowNoEquipmentFoundProps>) {
    const { item, setSelectedGeraet } = props;

    let device = item.lieferscheinItem;
    let lieferschein = item.lieferschein;

    return (
        <TableRow key={device.equipmentNummer}>
            <TableCell colSpan={4}>Kein Gerät gefunden</TableCell>
            <TableCell>{lieferschein.lieferscheinNummer}</TableCell>
            <TableCell>
                {item.sortierSchluessel && <span style={{ paddingRight: "4px" }}>{item.sortierSchluessel}</span>}
                {lieferschein?.farbe && <SquareIcon fontSize="small" htmlColor={lieferschein.farbe} />}
            </TableCell>
            <TableCell>
                <Button size="small" variant="outlined" onClick={() => setSelectedGeraet(item)}>
                    Auswählen
                </Button>
            </TableCell>
        </TableRow>
    );
}

type RowItemAndLieferscheinProps = {
    item: Sortierinformation;
    setSelectedGeraet: (geraet: Sortierinformation) => void;
};
export function RowItemAndLieferschein(props: Readonly<RowItemAndLieferscheinProps>) {
    const { item, setSelectedGeraet } = props;

    let device = item.lieferscheinItem;
    let lieferschein = item.lieferschein;

    if (lieferschein == null) {
        return (
            <TableRow>
                <TableCell>{device.equipmentNummer}</TableCell>
                <TableCell>{device.artikelNummer}</TableCell>
                <TableCell>{device.funktionsklasse}</TableCell>
                <TableCell>Kein Lieferschein vorhanden</TableCell>
                <TableCell>{item.sortierSchluessel && <span style={{ paddingRight: "4px" }}>{item.sortierSchluessel}</span>}</TableCell>
                <TableCell>
                    <Button size="small" variant="outlined">
                        Lieferschein anlegen
                    </Button>
                </TableCell>
            </TableRow>
        );
    }

    return (
        <TableRow key={device.equipmentNummer}>
            <TableCell>{device.equipmentNummer}</TableCell>
            <TableCell>{device.artikelNummer}</TableCell>
            <TableCell>{device.funktionsklasse}</TableCell>
            <TableCell>{lieferschein.lieferscheinNummer}</TableCell>
            <TableCell>
                {item.sortierSchluessel && <span style={{ paddingRight: "4px" }}>{item.sortierSchluessel}</span>}
                {lieferschein?.farbe && <SquareIcon fontSize="small" htmlColor={lieferschein.farbe} />}
            </TableCell>
            <TableCell>
                <Button size="small" variant="outlined" onClick={() => setSelectedGeraet(item)}>
                    Auswählen
                </Button>
            </TableCell>
        </TableRow>
    );
}
