import { Artikel } from "Models/Artikel";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { AnforderungsPosition } from "Models/AnforderungsPosition";
import { Kommissionierung } from "Models/Kommissionierung";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import Storno from "Models/Storno";
import StornoButtonBesteller from "./StornoButtonBesteller";
import useAuthentication from "authentication/useAuthentication";

type AnforderungsPositionenTabelleBestellerProps = {
    positionen: AnforderungsPosition[];
    kommissionierungen: Kommissionierung[];
    stornierungen: Storno[];
    stornoCallback: (posId: string) => void;
};
export default function AnforderungsPositionenTabelleBesteller(props: Readonly<AnforderungsPositionenTabelleBestellerProps>) {
    const { positionen, kommissionierungen, stornierungen, stornoCallback } = props;
    const calculateNochOffen = (pos: AnforderungsPosition, kommissionierungen: Kommissionierung[]) => {
        let anzahl = 0;
        kommissionierungen.forEach((k) => {
            k.positionen
                .filter((k) => k.anforderungsPosition.id === pos.id)
                .forEach((p) => {
                    anzahl += p.anzahl;
                });
        });
        stornierungen.forEach((s) => {
            if (s.anforderungsPosition.id === pos.id) {
                anzahl += s.stornierteAnzahl;
            }
        });
        return pos.bestellteAnzahl - anzahl;
    };

    const calculateStorniert = (pos: AnforderungsPosition, stornierungen: Storno[]) => {
        let anzahl = 0;
        stornierungen.forEach((s) => {
            if (s.anforderungsPosition.id === pos.id) {
                anzahl += s.stornierteAnzahl;
            }
        });
        return anzahl;
    };

    return (
        <>
            <h3>Abrufpositionen</h3>
            <Table
                size="small"
                sx={{
                    [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none",
                    },
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="left">Funktionsklasse</TableCell>
                        <TableCell>Artikelnummer</TableCell>
                        <TableCell>Bestellt</TableCell>
                        <TableCell>Noch offen</TableCell>
                        <TableCell>Storniert</TableCell>
                        <TableCell align="left">Lagerbestände</TableCell>
                        <TableCell align="left">Montageaufträge</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {positionen.map((pos: AnforderungsPosition, index: number) => {
                        return (
                            <AnforderungsPositionZeile
                                anforderungsPosition={pos}
                                key={index}
                                anforderungOffen={calculateNochOffen(pos, kommissionierungen)}
                                storniert={calculateStorniert(pos, stornierungen)}
                                stornoCallback={(posId: string) => {
                                    stornoCallback(posId);
                                }}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
}

export type AnforderungsPositionZeileProps = {
    anforderungsPosition: AnforderungsPosition;
    anforderungOffen: number;
    storniert: number;
    stornoCallback: (posId: string) => void;
};
export const AnforderungsPositionZeile = (props: AnforderungsPositionZeileProps) => {
    const { anforderungOffen, anforderungsPosition: pos, storniert, stornoCallback } = props;
    const { firmaIdAnforderer } = useAuthentication();

    let nochOffen = anforderungOffen > 0;

    return (
        <>
            <TableRow style={{ verticalAlign: "top", borderTop: "1px solid black" }}>
                <TableCell rowSpan={2} style={{ border: "none" }}>
                    {nochOffen ? <ScheduleIcon color="error" /> : <CheckCircleOutlineIcon color="success" />}
                </TableCell>
                <TableCell>{pos.artikel.funktionsklasse}</TableCell>
                <TableCell>{pos.artikel.artikelnummer}</TableCell>
                <TableCell>{pos.bestellteAnzahl}</TableCell>
                <TableCell>{anforderungOffen}</TableCell>
                <TableCell>{storniert}</TableCell>
                <TableCell rowSpan={2}>
                    <LagerBestaende artikel={pos.artikel} />
                </TableCell>
                <TableCell rowSpan={2}>
                    <MontageBestaende artikel={pos.artikel} />
                </TableCell>
                <TableCell>
                    <StornoButtonBesteller firmaId={firmaIdAnforderer} position={pos} nochOffen={anforderungOffen} stornoCallback={(posId: string) => stornoCallback(posId)} />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={2}>{pos.artikel.name}</TableCell>
            </TableRow>
        </>
    );
};

export const MontageBestaende = (props: { artikel: Artikel }) => {
    const { artikel } = props;

    if (artikel.montageAuftraege && artikel.montageAuftraege.length === 1) {
        return <>{artikel.montageAuftraege[0]?.anzahl}</>;
    } else {
        return <>0</>;
    }
};

export const LagerBestaende = (props: { artikel: Artikel }) => {
    const { artikel } = props;

    if (artikel.lagerBestaende && artikel.lagerBestaende.length === 1) {
        return <>{artikel.lagerBestaende[0]?.anzahl}</>;
    } else {
        return <>0</>;
    }
};
