import { useState } from "react";
import axiosClient from "ApiClient";

export default function usePost<T>(url: string) {
    const [data, setData] = useState<T | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const [route, setRoute] = useState<string>(url);

    const createObject = async (obj: T) => {
        setIsLoading(true);
        setError("");
        await axiosClient
            .post<T>(`${route}`, obj)
            .then((response) => {
                setData(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setError(error.message + ". " + error.response?.data);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return { data, isLoading, error, createObject, setRoute };
}
