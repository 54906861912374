import { useState } from "react";
import axiosClient from "ApiClient";

export default function usePutById<T>(route: string) {
    const [data, setData] = useState<T | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const putObject = async (theObject: T) => {
        if (theObject) {
            let tmpObject: any = theObject;
            if (tmpObject.id === undefined) {
                return;
            }
            setIsLoading(true);
            setError("");
            setData(undefined);
            await axiosClient
                .put<T>(`${route.replace("{id}", tmpObject.id)}`, theObject)
                .then((response) => {
                    setData(response.data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setError(error.message + " " + error.response?.data);
                    setIsLoading(false);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    return { data, isLoading, error, putObject };
}
