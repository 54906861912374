import SquareIcon from "@mui/icons-material/Square";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import ErrorDisplay from "applications/components/ErrorDisplay";
import { useContext, useEffect } from "react";
import Sortierinformation from "Models/Sortierinformation";
import Lieferschein from "Models/Lieferschein";
import usePost from "applications/hooks/usePost";
import { AppContextRuecklogistik } from "context/AppContextRuecklogistik";

type GeraeteScanDialogProps = {
    open: boolean;
    sortingInfo: Sortierinformation;
    onClose: () => void;
};
export default function GeraeteScanDialog(props: Readonly<GeraeteScanDialogProps>) {
    const { sortingInfo, open, onClose } = props;

    const { ausgangslager } = useContext(AppContextRuecklogistik);

    const { isLoading, error, data, createObject, setRoute } = usePost<Sortierinformation>(
        `angelo/ruecklogistik/alager/${ausgangslager?.id}/lieferscheine/${sortingInfo.lieferschein?.id}/items`
    );

    const closeRequest = (e: any) => {
        // Disables closing the dialog by clicking outside
        if (e.reason !== "backdropClick") {
            onClose();
        }
    };

    useEffect(() => {
        if (ausgangslager && sortingInfo) {
            setRoute(`angelo/ruecklogistik/alager/${ausgangslager?.id}/lieferscheine/${sortingInfo.lieferschein?.id}/items`);
        }
    }, [ausgangslager, sortingInfo]);

    const save = () => {
        const data = sortingInfo;
        data.lieferschein = { id: data.lieferschein.id } as Lieferschein;
        createObject(data);
    };

    useEffect(() => {
        if (data && !error) {
            onClose();
        }
    }, [data, error]);

    return (
        <Dialog open={open} onClose={() => closeRequest} fullWidth={true} maxWidth={"sm"}>
            <DialogTitle>Sortier Anweisung</DialogTitle>
            <DialogContent sx={{ height: "100%", alignContent: "center", textAlign: "center" }}>
                {isLoading && <LinearProgress />}
                {error && <ErrorDisplay title="Fehler beim Speichern des Geräts." error={error} />}
                <SortingInformationDisplay geraet={sortingInfo} />
            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                        onClose();
                    }}
                >
                    Abbrechen
                </Button>
                <Button disabled={isLoading} size="small" variant="contained" color="primary" onClick={() => save()}>
                    Bestätigen
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export function SortingInformationDisplay(props: Readonly<{ geraet: Sortierinformation }>) {
    const { geraet } = props;
    const item = geraet.lieferscheinItem;
    const lieferschein = geraet.lieferschein;
    return (
        <TableContainer>
            <Table sx={{ width: "100%" }}>
                <TableBody>
                    <TableRow>
                        <TableCell>Serialnummer</TableCell>
                        <TableCell>{item.serialNummer}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Equipmentnummer</TableCell>
                        <TableCell>{item.equipmentNummer}</TableCell>
                    </TableRow>
                    {lieferschein && (
                        <TableRow>
                            <TableCell>Lieferschein</TableCell>
                            <TableCell>{lieferschein.lieferscheinNummer}</TableCell>
                        </TableRow>
                    )}
                    {geraet.sortierSchluessel && (
                        <TableRow>
                            <TableCell>Sortierschlüssel</TableCell>
                            <TableCell>{geraet.sortierSchluessel}</TableCell>
                        </TableRow>
                    )}
                    {lieferschein?.farbe && (
                        <TableRow>
                            <TableCell>Farbe</TableCell>
                            <TableCell>
                                <SquareIcon fontSize="medium" htmlColor={lieferschein.farbe} />
                            </TableCell>
                        </TableRow>
                    )}
                    {!item.equipmentNummer && (
                        <TableRow>
                            <TableCell colSpan={2} align="center">
                                <Typography color="error">Bitte PZGL kontaktieren!</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

type NoLieferscheinInformation = {
    info: Sortierinformation;
};
export function NoLieferschein(props: Readonly<NoLieferscheinInformation>) {
    const { info } = props;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom color={"error"} sx={{ textAlign: "center" }}>
                    Kein Lieferschein gefunden!
                </Typography>
                Bitte einen neuen Lieferschein für {info.sortierSchluessel}-Geräte anlegen und erneut versuchen.
            </Grid>
        </Grid>
    );
}
