import { Button } from "@mui/material";
import useAuthentication from "authentication/useAuthentication";
import { UserRoles } from "Models/User";
import { Link as RouterLink, useMatch } from "react-router-dom";

export default function NavigationRuecklogistik() {
    const { hasRole } = useAuthentication();

    const isRuecklogistkRoute = useMatch("/ruecklogistik/*");
    const isScanRoute = useMatch("/ruecklogistik/scan");
    const isLieferscheinRoute = useMatch("/ruecklogistik/lieferscheine/*");
    const isLieferscheinHistorieRoute = useMatch("/ruecklogistik/lieferscheinhistorie");

    return (
        <>
            {isRuecklogistkRoute && hasRole(UserRoles.ERSTELLERRUECKLOGISTIK) && (
                <>
                    <Button component={RouterLink} to={"/ruecklogistik/scan"} className={isScanRoute ? "activeLink" : ""} sx={{ my: 2, color: "white", display: "block" }}>
                        Gerät scannen
                    </Button>
                    <Button
                        component={RouterLink}
                        to={"/ruecklogistik/lieferscheine"}
                        className={isLieferscheinRoute ? "activeLink" : ""}
                        sx={{ my: 2, color: "white", display: "block" }}
                    >
                        Lieferscheine
                    </Button>
                    <Button
                        component={RouterLink}
                        to={"/ruecklogistik/lieferscheinhistorie"}
                        className={isLieferscheinHistorieRoute ? "activeLink" : ""}
                        sx={{ my: 2, color: "white", display: "block" }}
                    >
                        Historie
                    </Button>
                </>
            )}
        </>
    );
}
