import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, MenuItem, Select, TextField } from "@mui/material";
import ErrorDisplay from "applications/components/ErrorDisplay";
import usePost from "applications/hooks/usePost";
import { Lager, LAGER_RUECKLOGISTIK, LAGER_ZENTRALLAGER } from "Models/Lager";
import Lieferschein, { GebindeForm, LieferscheinStatus, LieferscheinType } from "Models/Lieferschein";
import { useEffect, useState } from "react";

const emptyLieferschein: Lieferschein = {
    status: LieferscheinStatus.OFFEN,
    typ: LieferscheinType.ELEKTRONISCH,
    zielort: "Rücklogistik",
    gebinde: GebindeForm.GITTERBOX,
} as Lieferschein;

type CreateLieferscheinProps = {
    ausgangslager: Lager;
    input?: Lieferschein;
    onChange: (data: Lieferschein) => void;
};
export default function CreateLieferschein(props: Readonly<CreateLieferscheinProps>) {
    const { onChange, input, ausgangslager } = props;

    const [open, setOpen] = useState<boolean>(false);
    const [lieferschein, setLieferschein] = useState<Lieferschein>(input ?? { ...emptyLieferschein, ausgangslager, eingangslager: LAGER_RUECKLOGISTIK });

    const { data, error, isLoading, createObject } = usePost<Lieferschein>(`angelo/ruecklogistik/alager/${ausgangslager.id}/lieferscheine`);

    const close = () => {
        setOpen(false);
    };

    const save = () => {
        createObject(lieferschein);
    };

    useEffect(() => {
        setLieferschein({ ...lieferschein, ausgangslager });
    }, [ausgangslager]);

    useEffect(() => {
        if (data) {
            onChange(data);
            setOpen(false);
        }
    }, [data]);

    // Zielort and elager depend on Typ
    useEffect(() => {
        let zielort;
        let elager;

        switch (lieferschein.typ) {
            case LieferscheinType.BEFUND:
            case LieferscheinType.UNBEKANNT:
            case LieferscheinType.WANDLER:
                zielort = "Esslingen";
                elager = LAGER_ZENTRALLAGER;
                break;

            case LieferscheinType.ELEKTRONISCH:
            case LieferscheinType.MECHANISCH:
            case LieferscheinType.SONSTIGES:
            default:
                zielort = "Rücklogistik";
                elager = LAGER_RUECKLOGISTIK;
                break;
        }
        setLieferschein({ ...lieferschein, zielort, eingangslager: elager });
    }, [lieferschein.typ]);

    return (
        <>
            <Button size="small" variant="outlined" onClick={() => setOpen(true)}>
                Neuer Lieferschein
            </Button>
            <Dialog open={open} onClose={() => close()} maxWidth="sm" fullWidth={true}>
                <DialogTitle>Lieferschein anlegen</DialogTitle>
                <DialogContent>
                    {isLoading && <LinearProgress />}
                    {error && <ErrorDisplay title="Fehler beim Speichern des Lieferscheins" error={error} />}
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            Lieferscheinnummer
                        </Grid>
                        <Grid item xs={8}>
                            <TextField disabled={true} variant="standard" placeholder="von SAP vergeben" fullWidth value={lieferschein.lieferscheinNummer} />
                        </Grid>
                        <Grid item xs={4}>
                            Typ
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                onChange={(evt) => {
                                    setLieferschein({ ...lieferschein, typ: evt.target.value as LieferscheinType });
                                }}
                                value={lieferschein.typ}
                                size="small"
                                variant="standard"
                                fullWidth
                            >
                                {Object.values(LieferscheinType).map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={4}>
                            Gebinde
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                onChange={(evt) => {
                                    setLieferschein({ ...lieferschein, gebinde: evt.target.value as GebindeForm });
                                }}
                                value={lieferschein.gebinde}
                                size="small"
                                variant="standard"
                                fullWidth
                            >
                                {Object.keys(GebindeForm).map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={4}>
                            Ausgangslager
                        </Grid>
                        <Grid item xs={8}>
                            <TextField value={lieferschein.ausgangslager.aNummer} variant="standard" size="small" fullWidth disabled={true} />
                        </Grid>
                        <Grid item xs={4}>
                            Eingangslager
                        </Grid>
                        <Grid item xs={8}>
                            <TextField value={lieferschein.eingangslager.nummer} variant="standard" size="small" fullWidth disabled={true} />
                        </Grid>
                        <Grid item xs={4}>
                            Transitlager
                        </Grid>
                        <Grid item xs={8}>
                            <TextField value={lieferschein.transitlager} variant="standard" size="small" fullWidth disabled={true} placeholder="von SAP vergeben" />
                        </Grid>
                        <Grid item xs={4}>
                            Zielort
                        </Grid>
                        <Grid item xs={8}>
                            <TextField value={lieferschein.zielort} variant="standard" size="small" fullWidth disabled={true} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="outlined" onClick={() => close()}>
                        Abbrechen
                    </Button>
                    <Button size="small" variant="outlined" onClick={() => save()}>
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
