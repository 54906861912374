import { useEffect, useState } from "react";
import axiosClient from "ApiClient";
import { GridPaginationModel } from "@mui/x-data-grid-pro";

export const defaultPagination = { page: 0, pageSize: 20 } as GridPaginationModel;

export type SortItem = {
    field: string;
    sort: "asc" | "desc" | null | undefined;
};

export type GetFilteredResultModel<T> = {
    count: number;
    results: T;
};
export default function useGetFiltered<T>(route: string, initialFilter: any, initialPagination?: GridPaginationModel | null) {
    const [data, setData] = useState<GetFilteredResultModel<T>>({
        count: 0,
        results: [] as T,
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [filter, setFilter] = useState<any>(initialFilter);
    const [sort, setSort] = useState<SortItem>();
    const [pagination, setPagination] = useState<GridPaginationModel>(initialPagination ?? defaultPagination);

    const getFilterString = () => {
        if (!filter && !sort && !pagination) {
            return "";
        }
        let res = "?";
        if (filter) {
            Object.keys(filter).forEach((key) => {
                if (filter[key] && Array.isArray(filter[key])) {
                    filter[key].forEach((element: any) => {
                        res += `${key}=${element}&`;
                    });
                } else if (filter[key] instanceof Date) {
                    res += `${key}=${filter[key].toISOString()}&`;
                } else {
                    res += `${key}=${filter[key]}&`;
                }
            });
        }
        if (sort) {
            res += `sort=${sort.field}&sortOrder=${sort.sort}&`;
        }
        if (pagination) {
            res += `page=${pagination.page}&pageSize=${pagination.pageSize}&`;
        }
        return res;
    };

    const load = async () => {
        setIsLoading(true);
        setError("");

        await axiosClient
            .get<GetFilteredResultModel<T>>(`${route}${getFilterString()}`)
            .then((response) => {
                setData(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setError(error.message + " " + error.response?.data);
                setIsLoading(false);
                setData({
                    count: 0,
                    results: [] as T,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (!isLoading) {
            load();
        }
    }, []);

    useEffect(() => {
        if (!isLoading) {
            load();
        }
    }, [sort, pagination, filter]);

    return { data, isLoading, error, load, setFilter, setSort, pagination, setPagination };
}
