import { AppContext } from "context/AppContext";
import { useContext, useEffect } from "react";
import { Button, LinearProgress } from "@mui/material";
import { Lager } from "Models/Lager";
import useAuthentication from "authentication/useAuthentication";

import usePutById from "applications/hooks/usePutById";
import ErrorDisplay from "applications/components/ErrorDisplay";
import useGetDynamicPath from "applications/hooks/useGetDynamicPath";

type PutLagerType = {
    id: number;
    lagerid: number;
};

type WarenkorbLagerPickerProps = {
    onSelect: (lager: Lager) => void;
};
/**
 * Change and set the Lager for the Warenkorb.
 * @param props
 * @returns
 */
export default function WarenkorbLagerPicker(props: Readonly<WarenkorbLagerPickerProps>) {
    const { firmaIdAnforderer } = useAuthentication();
    const { cartLager, setCartLager, cartItemCount, setCartItemCount } = useContext(AppContext);
    const { onSelect } = props;
    const { data, error, isLoading, load } = useGetDynamicPath<Lager>("/angelo/firmen/{firmaId}/lager");
    const { isLoading: putIsLoading, error: putError, putObject } = usePutById<PutLagerType>("/angelo/warenkorb/lager");

    useEffect(() => {
        if (firmaIdAnforderer && !isLoading) {
            let params = [{ key: "firmaId", value: firmaIdAnforderer }];
            load(params);
        }
    }, [firmaIdAnforderer]);

    /**
     * Change selected lager on server and update context.
     * @param lager
     */
    const changeLager = async (lager: Lager) => {
        let putLager = { id: lager.id, lagerid: lager.id } as PutLagerType;
        await putObject(putLager);
        setCartLager(lager);
        setCartItemCount(0);
        onSelect(lager);
    };

    return (
        <div style={{ marginBottom: "30px" }}>
            <h4>Wähle ein Lager für deinen Abruf aus</h4>
            {isLoading && <LinearProgress />}
            {error && <ErrorDisplay error={error} title="Fehler beim Laden der Lager" />}
            {putError && <ErrorDisplay error={putError} title="Fehler beim Setzen der Lager" />}
            <div style={{ marginBottom: "30px" }}>Artikel in deinem Warenkorb werden immer auf das aktuell ausgewählte Lager bestellt.</div>
            {data?.map((l, no) => {
                return (
                    <Button
                        disabled={putIsLoading}
                        sx={{ marginLeft: "20px" }}
                        onClick={async () => {
                            if (data.length > 1 && cartLager !== undefined && cartLager !== l && cartItemCount > 0) {
                                let confirmClearing = window.confirm(
                                    "Du hast bereits Artikel im Warenkorb für ein anderes Lager.\nDer Warenkorb wird geleert wenn du das Lager wechselst.\n\nBist du dir sicher?"
                                );

                                if (confirmClearing) {
                                    changeLager(l);
                                }
                            } else {
                                changeLager(l);
                            }
                        }}
                        variant={cartLager?.id === l.id ? "contained" : "outlined"}
                        key={no}
                    >
                        {l.name + " " + l.nummer}
                    </Button>
                );
            })}
            <hr style={{ marginTop: "30px" }}></hr>
        </div>
    );
}
