import { Button } from "@mui/material";
import useWarenkorb from "applications/bestellung/Warenkorb/useWarenkorb";
import useGetFiltered from "applications/hooks/useGetFiltered";
import { OffeneBestellAnforderungsFilter } from "applications/logistik/anforderungen/AnforderungsFilter/AnfoderungsFilter";
import useAuthentication from "authentication/useAuthentication";
import { AppContext } from "context/AppContext";
import { Anforderung } from "Models/Anforderung";
import { UserRoles } from "Models/User";
import { useContext, useEffect } from "react";
import { Link as RouterLink, useMatch } from "react-router-dom";

const FIRMA_URL = "/angelo/firmen/{firmaId}/anforderungen";

export default function NavigationBestellung() {
    const { hasRole, firmaIdAnforderer } = useAuthentication();
    const { cartItemCount: cartItems, setCartItemCount: setCartItems, amountOffeneAnforderungen, setAmountOffeneAnforderungen } = useContext(AppContext);

    const { warenkorb } = useWarenkorb();

    const { data } = useGetFiltered<Anforderung[]>(FIRMA_URL.replace("{firmaId}", firmaIdAnforderer), OffeneBestellAnforderungsFilter);

    const isBestellungRoute = useMatch("/bestellung/*");

    const isArtikel = useMatch("/bestellung/artikel");
    const isWarenkorb = useMatch("/bestellung/warenkorb");
    const isOffeneAnforderungen = useMatch("/bestellung/offeneAnforderungen/*");
    const isAnforderungsHistorie = useMatch("/bestellung/anforderungshistorie/*");
    const isMeineFirma = useMatch("/bestellung/meineFirma");
    const isLagerBestand = useMatch("/bestellung/lagerbestand");

    useEffect(() => {
        if (warenkorb) {
            setCartItems(warenkorb?.bestellpositionen.length ?? 0);
        }
    }, [warenkorb, setCartItems]);

    useEffect(() => {
        setAmountOffeneAnforderungen(data.count);
    }, [data]);

    return (
        <>
            {isBestellungRoute && hasRole(UserRoles.ANFORDERER) && (
                <>
                    <Button
                        component={RouterLink}
                        to="/bestellung/artikel"
                        className={isArtikel ? "activeLink" : ""}
                        key="artikel"
                        sx={{ my: 2, color: "white", display: "block" }}
                    >
                        Artikel
                    </Button>
                    <Button
                        component={RouterLink}
                        to="/bestellung/warenkorb"
                        className={isWarenkorb ? "activeLink" : ""}
                        key="warenkorb"
                        sx={{ my: 2, color: "white", display: "block" }}
                    >
                        Warenkorb ({cartItems})
                    </Button>
                    <Button
                        component={RouterLink}
                        to={"/bestellung/offeneAnforderungen"}
                        className={isOffeneAnforderungen ? "activeLink" : ""}
                        key="offeneAnforderungen"
                        sx={{ my: 2, color: "white", display: "block" }}
                    >
                        Offene Anforderungen ({amountOffeneAnforderungen})
                    </Button>
                    <Button
                        component={RouterLink}
                        to={"/bestellung/anforderungshistorie"}
                        className={isAnforderungsHistorie ? "activeLink" : ""}
                        key="anforderungsHistorie"
                        sx={{ my: 2, color: "white", display: "block" }}
                    >
                        Anforderungshistorie
                    </Button>

                    <Button component={RouterLink} to="/bestellung/meineFirma" className={isMeineFirma ? "activeLink" : ""} sx={{ my: 2, color: "white", display: "block" }}>
                        Meine Firma
                    </Button>
                    <Button component={RouterLink} to="/bestellung/lagerbestand" className={isLagerBestand ? "activeLink" : ""} sx={{ my: 2, color: "white", display: "block" }}>
                        Lagerbestand
                    </Button>
                </>
            )}
        </>
    );
}
