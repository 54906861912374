import { Button } from "@mui/material";
import useAuthentication from "authentication/useAuthentication";
import Header from "layout/Header";
import Navigation from "layout/Navigation";
import { UserRoles } from "Models/User";

type SecuredPageAdministratorProps = {
    children: JSX.Element;
};

function SecuredPageAdministrator(props: Readonly<SecuredPageAdministratorProps>) {
    const { children } = props;
    const { isAuthenticated, hasRole, logout } = useAuthentication();

    if (!isAuthenticated || !hasRole(UserRoles.ADMINISTRATOR, UserRoles.KOORDINATOR)) {
        return (
            <>
                <Header />
                <Navigation />
                <div className="contentDiv">
                    Die von dir aufgerufene Seite ist nur für Administratoren zugänglich!
                    <br /> Du bist nicht authorisiert diese Seite zu betreten!
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            logout();
                        }}
                    >
                        Neu anmelden
                    </Button>
                </div>
            </>
        );
    } else {
        return (
            <>
                <Header />
                <Navigation />
                {children}
            </>
        );
    }
}
export default SecuredPageAdministrator;
