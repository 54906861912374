import ControlPointIcon from "@mui/icons-material/ControlPoint";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ImageIcon from "@mui/icons-material/Image";
import { Button, LinearProgress } from "@mui/material";
import { DataGridPro, GridActionsCellItem, GridColDef, GridColumnHeaderParams, GridRowParams } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { config } from "config";
import AuthenticatedImg from "layout/AuthenticatedImage";
import CustomGridToolbarWithExport from "layout/CustomGridToolbarWithExport";
import { Artikel } from "Models/Artikel";
import CreateArtikelDialog from "./CreateArtikelDialog";
import { useSetArtikelImage } from "./useSetArtikelImage";
import { ErrorDialog } from "applications/components/ErrorDialog";
import { useGetAllArtikel } from "applications/logistik/artikel/useGetAllArtikel";
import usePutById from "applications/hooks/usePutById";

export default function ArtikelMgmtList() {
    const ARTIKEL_COLUMN_WIDTHS = "ArtikelTabelleBreiten";
    const { data, error, loaded, toggleRefresh } = useGetAllArtikel();
    const { error: updateError, putObject: updateArtikel, isLoading } = usePutById<Artikel>("/angelo/admin/artikel/{id}1");
    const { setArtikelImage, loaded: imageLoaded } = useSetArtikelImage();

    const [showCreateArtikel, setShowCreateArtikel] = useState(false);

    const getColumnWidth = (name: string) => {
        if (Object.keys(columnWidths).includes(name)) {
            let entry = Object.entries(columnWidths).find((e) => e[0] === name);
            if (entry) {
                return entry[1];
            }
        }
        // in case of error return default.
        return 145;
    };

    const [columnWidths, setColumnWidths] = useState({
        actions: 70,
        artikelnummer: 200,
        name: 300,
        activated: 90,
        imageData: 80,
        planGrundTyp: 125,
        planSchluessel: 125,
        art: 100,
        funktionsklasse: 135,
        bauklasse: 100,
        planungsjahr: 120,
        bemerkungen: 150,
    });

    const setColumnWidth = (e: any) => {
        let newWidths = { ...columnWidths, [e.colDef.field]: e.width };
        setColumnWidths(newWidths);
        localStorage.setItem(ARTIKEL_COLUMN_WIDTHS, JSON.stringify(newWidths));
    };

    useEffect(() => {
        // init TabelleBreiten when loading.
        let tabelleBreiten = localStorage.getItem(ARTIKEL_COLUMN_WIDTHS);
        if (tabelleBreiten) {
            try {
                let newBreiten = JSON.parse(tabelleBreiten);
                setColumnWidths(newBreiten);
            } catch (error) {
                console.log(`Error on reading ${ARTIKEL_COLUMN_WIDTHS} from localstorage. ERROR: ${error}`);
            }
        }
    }, []);

    function renderHeaderWithEditIcon(p: GridColumnHeaderParams<any, Artikel, any>) {
        return (
            <>
                <span>{p.colDef.headerName}</span>
                <EditOutlinedIcon fontSize="small" color="action" style={{ marginLeft: 2 }} />
            </>
        );
    }

    const getImagePicker = (id: string) => {
        return (
            <>
                <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id={"imageFile" + id}
                    type="file"
                    onChange={async (e) => {
                        let file = e.currentTarget.files![0];
                        await setArtikelImage(id, file);
                        toggleRefresh();
                        e.stopPropagation();
                    }}
                />
                <label htmlFor={"imageFile" + id}>
                    <ImageIcon titleAccess="Bild ändern" />
                </label>
            </>
        );
    };

    const columns: GridColDef<Artikel>[] = [
        {
            field: "actions",
            type: "actions",
            width: getColumnWidth("actions"),
            getActions: (params: GridRowParams) => {
                let actions = [<GridActionsCellItem key="changeImage" showInMenu={false} label="Bild ändern" icon={getImagePicker(params.id.toString())} />];
                if (params.row.activated) {
                    actions.push(
                        <GridActionsCellItem
                            key="deactivate"
                            showInMenu={false}
                            icon={<HighlightOffIcon titleAccess="Deaktivieren" />}
                            label="Deaktivieren"
                            onClick={(e) => {
                                let artikelToDeactivate = data.find((a) => a.id === params.id);
                                artikelToDeactivate!.activated = false;
                                updateArtikel(artikelToDeactivate!);
                                e.stopPropagation();
                            }}
                        />
                    );
                } else {
                    actions.push(
                        <GridActionsCellItem
                            key="activate"
                            showInMenu={false}
                            icon={<ControlPointIcon titleAccess="Aktivieren" />}
                            label="Aktivieren"
                            onClick={(e) => {
                                let artikelToActivate = data.find((a) => a.id === params.id);
                                artikelToActivate!.activated = true;
                                updateArtikel(artikelToActivate!);
                                e.stopPropagation();
                            }}
                        />
                    );
                }
                return actions;
            },
        },
        {
            field: "artikelnummer",
            headerName: "Artikelnummer",
            width: getColumnWidth("artikelnummer"),
            editable: true,
            renderHeader: renderHeaderWithEditIcon,
        },
        {
            field: "name",
            headerName: "Name",
            width: getColumnWidth("name"),
            editable: true,
            renderHeader: renderHeaderWithEditIcon,
        },
        {
            field: "activated",
            headerName: "Deaktiviert",
            width: getColumnWidth("activated"),
            valueGetter: (value) => {
                return value ? "Nein" : "Ja";
            },
        },
        {
            field: "imageData",
            headerName: "Bild",
            width: getColumnWidth("imageData"),
            renderCell: (p) => {
                let article = p.row;
                if (!article.hasImage) {
                    return null;
                }
                return <AuthenticatedImg alt={"Artikel " + p.row.id} src={config.BASE_URL + "angelo/artikel/" + p.row.id + "/image"} inList={true} artikelName={p.row.name} />;
            },
        },
        {
            field: "planGrundTyp",
            headerName: "Plan-Grundtyp",
            width: getColumnWidth("planGrundTyp"),
            editable: true,
            renderHeader: renderHeaderWithEditIcon,
        },
        {
            field: "planSchluessel",
            headerName: "Plan-Schlüssel",
            width: getColumnWidth("planSchluessel"),
            renderHeader: renderHeaderWithEditIcon,
            editable: true,
        },
        {
            field: "art",
            headerName: "Art",
            width: getColumnWidth("art"),
            renderHeader: renderHeaderWithEditIcon,
            editable: true,
        },
        {
            field: "funktionsklasse",
            headerName: "Funktionsklasse",
            width: getColumnWidth("funktionsklasse"),
            renderHeader: renderHeaderWithEditIcon,
            editable: true,
        },
        {
            field: "bauklasse",
            headerName: "Bauklasse",
            width: getColumnWidth("bauklasse"),
            renderHeader: renderHeaderWithEditIcon,
            editable: true,
        },
        {
            field: "planungsjahr",
            renderHeader: renderHeaderWithEditIcon,
            headerName: "Planungsjahr",
            width: getColumnWidth("planungsjahr"),
            type: "number",
            valueFormatter(params) {
                return params ?? 0;
            },
            editable: true,
        },
        {
            field: "regalplatz",
            headerName: "Stellplatz",
            width: getColumnWidth("Stellplatz"),
            renderHeader: renderHeaderWithEditIcon,
            editable: true,
        },
        {
            field: "bemerkungen",
            headerName: "Bemerkung",
            width: getColumnWidth("bemerkungen"),
            renderHeader: renderHeaderWithEditIcon,
            editable: true,
        },
    ];

    return (
        <div className="contentDiv">
            {error && <ErrorDialog title="Fehler beim laden der Artikel" message="Ein Fehler ist aufgetreten!" error={error} />}
            <Button
                style={{ marginBottom: "30px" }}
                variant="contained"
                onClick={() => {
                    setShowCreateArtikel(true);
                }}
            >
                Neuer Artikel
            </Button>
            <CreateArtikelDialog
                show={showCreateArtikel}
                onClose={() => {
                    setShowCreateArtikel(false);
                    toggleRefresh();
                }}
            />
            {updateError && (
                <ErrorDialog
                    title="Update Fehlgeschlagen!"
                    message="Konnte den Artikel nicht speichern! Die Kombination aus Plan-Grundtyp und Plan-Schlüssel muss eindeutig sein."
                    error={updateError}
                    onClose={() => toggleRefresh()}
                />
            )}
            <DataGridPro
                autoHeight
                pagination
                onColumnWidthChange={setColumnWidth}
                processRowUpdate={async (updatedRow, originalRow) => {
                    await updateArtikel(updatedRow);
                    return updatedRow;
                }}
                onProcessRowUpdateError={() => {}}
                sx={{
                    "& .light-opacity": {
                        opacity: 0.5,
                        bgcolor: "lightgrey",
                    },
                }}
                getRowClassName={(params) => {
                    return params.row.activated ? "" : "light-opacity";
                }}
                density="compact"
                loading={!loaded || isLoading || !imageLoaded}
                getRowId={(r) => r.id}
                rows={data}
                columns={columns}
                slots={{
                    toolbar: CustomGridToolbarWithExport as any,
                    loadingOverlay: LinearProgress as any,
                }}
            />
        </div>
    );
}
