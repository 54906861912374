import "./index.css";
import Home, { LoginScreen } from "./Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ThemeProvider } from "@mui/material";
import { AppContextProvider } from "./context/AppContext";
import NetzeBW_Theme from "./layout/NetzeBW_Theme";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import msalInstance2 from "./authentication/msalInstance";

import RoutesAdministration from "applications/administration/RoutesAdministration";
import RoutesBestellung from "applications/bestellung/RoutesBestellung";
import RoutesLogistik from "applications/logistik/RoutesLogistik";
import RoutesRuecklogistik from "applications/ruecklogistik/RoutesRuecklogistik";
import SecuredPageRueckLogistik from "applications/ruecklogistik/SecuredPageRueckLogistik";
import SecuredPageLogistik from "applications/logistik/SecuredPageLogistik";
import SecuredPageAdministrator from "applications/administration/SecuredPageAdministrator";
import SecuredPageBestellung from "applications/bestellung/SecuredPageBestellung";
import { AppContextRuecklogistikProvider } from "context/AppContextRuecklogistik";

export default function App() {
    return (
        <ThemeProvider theme={NetzeBW_Theme}>
            <MsalProvider instance={msalInstance2}>
                <UnauthenticatedTemplate>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/*" element={<LoginScreen />} />
                        </Routes>
                    </BrowserRouter>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <AppContextProvider>
                        <AppContextRuecklogistikProvider>
                            <BrowserRouter>
                                <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route
                                        path="bestellung/*"
                                        element={
                                            <SecuredPageBestellung>
                                                <RoutesBestellung />
                                            </SecuredPageBestellung>
                                        }
                                    />

                                    <Route
                                        path="logistik/*"
                                        element={
                                            <SecuredPageLogistik>
                                                <RoutesLogistik />
                                            </SecuredPageLogistik>
                                        }
                                    />

                                    <Route
                                        path="ruecklogistik/*"
                                        element={
                                            <SecuredPageRueckLogistik>
                                                <RoutesRuecklogistik />
                                            </SecuredPageRueckLogistik>
                                        }
                                    />

                                    <Route
                                        path="administration/*"
                                        element={
                                            <SecuredPageAdministrator>
                                                <RoutesAdministration />
                                            </SecuredPageAdministrator>
                                        }
                                    />
                                </Routes>
                            </BrowserRouter>
                        </AppContextRuecklogistikProvider>
                    </AppContextProvider>
                </AuthenticatedTemplate>
            </MsalProvider>
        </ThemeProvider>
    );
}
