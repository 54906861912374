import { useEffect, useState } from "react";
import { Box, CircularProgress, IconButton, TextField, Tooltip } from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { ErrorDialog } from "applications/components/ErrorDialog";

import usePutById from "applications/hooks/usePutById";

type UpdateCartItemModel = {
    id: number;
    newAnzahl: number;
};

type UpdateCartItemProps = {
    item: CartItem;
    max: number;
    amount: number;
    onChanged: (val: number) => void;
};
export default function UpdateCartItem(props: Readonly<UpdateCartItemProps>) {
    const { max, amount, item, onChanged } = props;
    const [value, setValue] = useState<number>(amount);

    const { data, error, isLoading, putObject } = usePutById<UpdateCartItemModel>("angelo/warenkorb/bestellposition/{id}");

    const valueChanged = amount !== value;

    const save = async () => {
        if (valueChanged && !isNaN(value)) {
            let uItem = {
                id: item.id,
                newAnzahl: value,
            } as UpdateCartItemModel;
            await putObject(uItem);
        }
    };

    useEffect(() => {
        if (data) {
            onChanged(value);
        }
    }, [data]);

    return (
        <Box>
            <TextField
                sx={{ width: "50px" }}
                type="number"
                variant="standard"
                value={value}
                size="small"
                onChange={(evt) => {
                    let val = parseInt(evt.target.value);
                    if (val <= 0) {
                        setValue(0);
                    } else if (val > max) {
                        setValue(max);
                    } else {
                        setValue(val);
                    }
                }}
                onBlur={() => {
                    save();
                }}
            />
            {isLoading && <CircularProgress size={18} sx={{ marginLeft: 1, marginTop: 1 }} />}
            {!isLoading && (
                <IconButton
                    size="small"
                    disabled={!valueChanged}
                    onClick={() => {
                        //save(); // we already save onBlur
                    }}
                >
                    <Box sx={{ fontSize: "18px" }}>
                        <Tooltip title={error || "Anzahl anpassen"}>
                            <AddShoppingCartIcon fontSize="small" color={error ? "error" : "inherit"} />
                        </Tooltip>
                        {amount > 0 ? amount : ""}
                    </Box>
                </IconButton>
            )}
            {error && <ErrorDialog error={error} title={"Fehler beim Speichern von Warenkorb"} />}
        </Box>
    );
}
