import { Lager } from "Models/Lager";
import React, { useMemo, useState } from "react";

type AppContextRuecklogistikInterface = {
    ausgangslager: Lager | undefined;
    setAusgangslager: (newLager: Lager | undefined) => void;
};

export const AppContextRuecklogistik = React.createContext<AppContextRuecklogistikInterface>({
    ausgangslager: undefined,
    setAusgangslager: () => {},
});

type AppContextRuecklogistikProviderProps = {
    children: JSX.Element[] | JSX.Element;
};

export function AppContextRuecklogistikProvider(props: Readonly<AppContextRuecklogistikProviderProps>) {
    const [ausgangslager, setAusgangslager] = useState<Lager>();

    const initialValues: AppContextRuecklogistikInterface = useMemo(
        () => ({
            ausgangslager: ausgangslager,
            setAusgangslager: setAusgangslager,
        }),
        [ausgangslager, setAusgangslager]
    );

    return <AppContextRuecklogistik.Provider value={initialValues}>{props.children}</AppContextRuecklogistik.Provider>;
}
