import { ExpandLess, ExpandMore, FilterAltOff } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { Button, ButtonGroup, Divider, FormControl, Grid2, InputAdornment, InputLabel, LinearProgress, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useGet from "applications/hooks/useGet";
import useAuthentication from "authentication/useAuthentication";
import dayjs from "dayjs";
import SplitButton, { SplitButtonOptionsProps } from "layout/SplitButton";
import { AnforderungsTyp, BearbeitungsStatus } from "Models/Anforderung";
import Kommissionierer from "Models/Kommissionierer";
import { UserRoles } from "Models/User";
import { useEffect, useState } from "react";
import { AnforderungsFilter, DefaultAnforderungsFilter } from "./AnfoderungsFilter";
import { PredefinedAnforderungsFilter } from "./PredefinedAnforderungsFilter";
import useGetLager from "applications/logistik/LagerBestand/useGetLager";

type AuftragFilterToolbarProps = {
    onSearch: (filter: any) => void;
    isHistory: boolean;
};

export default function AuftragFilterToolbar(props: Readonly<AuftragFilterToolbarProps>) {
    const { onSearch, isHistory } = props;
    const user = useAuthentication();

    const predefindedFilters = PredefinedAnforderungsFilter.map((filter) => {
        return {
            name: filter.name,
            disabled: false,
        } as SplitButtonOptionsProps;
    });

    const [extendFilter, setExtendFilter] = useState<boolean>(false);

    const [filter, setFilter] = useState<any>(DefaultAnforderungsFilter);

    const reset = () => {
        setFilter(DefaultAnforderungsFilter);
        onSearch(DefaultAnforderungsFilter);
    };

    const allowedBearbeitungsStatus = () => {
        let result = [BearbeitungsStatus.Offen, BearbeitungsStatus.InKommissionierung, BearbeitungsStatus.Teillieferung, BearbeitungsStatus.Versendet];
        if (isHistory) {
            result = [BearbeitungsStatus.Abgeschlossen];
        }
        return result;
    };

    const splitButtonClicked = (index: number) => {
        const filter = { ...PredefinedAnforderungsFilter[index].filter };
        if (isHistory) {
            filter.status = allowedBearbeitungsStatus();
        }
        setFilter(filter);
        onSearch(filter);
    };

    return (
        <Grid2 container sx={{ alignItems: "end", padding: "4px", width: "100%" }}>
            <Grid2 size={4} sx={{ display: "flex", flex: 1, flexDirection: "row", padding: "0 4px" }}>
                <TextField
                    sx={{ padding: "0 4px" }}
                    variant="standard"
                    placeholder="Suchen"
                    size="small"
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            onSearch(filter);
                        }
                    }}
                    onChange={(e) => {
                        const value = e.currentTarget.value;
                        setFilter({ ...filter, searchphrase: value });
                    }}
                    value={filter.searchphrase}
                    slotProps={{
                        input: { startAdornment: <InputAdornment position="start"></InputAdornment> },
                    }}
                />
                <Button
                    sx={{ height: "32px", marginBottom: "4px", padding: "4px" }}
                    onClick={() => onSearch(filter)}
                    variant={filter.searchphrase ? "contained" : "outlined"}
                    size="small"
                    title="Suchen"
                >
                    <SearchIcon fontSize="medium" />
                </Button>
            </Grid2>
            <Grid2 size={4} sx={{ padding: "4px" }}>
                {user.hasRole(UserRoles.KOORDINATOR, UserRoles.ADMINISTRATOR) && (
                    <SplitButton options={predefindedFilters} size="small" variant="outlined" onClick={splitButtonClicked} onSelectedChanged={splitButtonClicked}></SplitButton>
                )}
            </Grid2>
            <Grid2 size={4} sx={{ padding: "4px", height: "40px" }}>
                <ButtonGroup variant="outlined" size="small" fullWidth>
                    <Button onClick={() => reset()} variant="outlined" title="Zurücksetzen">
                        <FilterAltOff fontSize="medium" />
                    </Button>
                    {user.hasRole(UserRoles.KOORDINATOR, UserRoles.ADMINISTRATOR) && (
                        <Button onClick={() => setExtendFilter(!extendFilter)} variant={extendFilter ? "contained" : "outlined"} title="Erweiterte Filtereinstellungen">
                            {extendFilter && <ExpandLess fontSize="medium" />}
                            {!extendFilter && <ExpandMore fontSize="medium" />}
                        </Button>
                    )}
                </ButtonGroup>
            </Grid2>
            <Grid2 size={12}>
                {user.hasRole(UserRoles.KOORDINATOR, UserRoles.ADMINISTRATOR) && extendFilter && (
                    <ExtendedAuftragFilter filter={filter} onFilterChange={setFilter} allowedBearbeitungsStatus={allowedBearbeitungsStatus()} />
                )}
            </Grid2>
        </Grid2>
    );
}

type ExtendedAuftragFilterProps = {
    filter: AnforderungsFilter;
    allowedBearbeitungsStatus: BearbeitungsStatus[];
    onFilterChange: (filter: AnforderungsFilter) => void;
};
function ExtendedAuftragFilter(props: Readonly<ExtendedAuftragFilterProps>) {
    const { filter, allowedBearbeitungsStatus, onFilterChange } = props;
    const user = useAuthentication();
    const [internalFilter, setInternalFilter] = useState<AnforderungsFilter>(filter);
    const { data: lagerData, loaded: lagerLoaded } = useGetLager(false);
    const { data: kommissioniererData, isLoading: kommissionierernIsLoaded } = useGet<Kommissionierer>("logistik/kommissionierer");

    const kommissioniererFilterAllowed = () => {
        return user.hasRole(UserRoles.KOORDINATOR, UserRoles.ADMINISTRATOR);
    };

    useEffect(() => {
        setInternalFilter(filter);
    }, [filter]);

    useEffect(() => {
        onFilterChange(internalFilter);
    }, [internalFilter]);

    return (
        <Grid2 container sx={{ alignItems: "end", width: "100%" }}>
            <Grid2 size={12} sx={{ padding: "4px" }}>
                <Divider />
            </Grid2>
            <Grid2 size={4} sx={{ padding: "4px" }}>
                <FormControl sx={{ width: "100%" }} size="small">
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filter.status}
                        size="small"
                        multiple={true}
                        onChange={(v) => {
                            const value = v.target.value as BearbeitungsStatus[];
                            setInternalFilter({ ...filter, status: value });
                        }}
                    >
                        {allowedBearbeitungsStatus.map((status) => (
                            <MenuItem value={status} key={status}>
                                {status}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid2>
            <Grid2 size={4} sx={{ padding: "4px" }}>
                <FormControl sx={{ width: "100%" }} size="small">
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label-type"
                        id="demo-simple-select-type"
                        value={filter.typ}
                        size="small"
                        multiple={true}
                        onChange={(v) => {
                            const value = v.target.value as AnforderungsTyp[];
                            setInternalFilter({ ...filter, typ: value });
                        }}
                    >
                        {Object.keys(AnforderungsTyp).map((key) => (
                            <MenuItem value={key} key={key}>
                                {key}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid2>
            <Grid2 size={4} sx={{ padding: "4px" }}>
                {!lagerLoaded && <LinearProgress />}
                <FormControl sx={{ width: "100%" }} size="small">
                    <InputLabel id="demo-simple-select-label-lager">Lager</InputLabel>
                    <Select
                        labelId="demo-simple-select-label-lager"
                        id="demo-simple-select-lager"
                        value={filter.lager}
                        size="small"
                        disabled={!lagerData || lagerData.length === 0}
                        multiple={true}
                        onChange={(v) => {
                            const value = v.target.value as string[];
                            setInternalFilter({ ...filter, lager: value });
                        }}
                    >
                        {lagerData
                            .toSorted((a, b) => a.nummer.localeCompare(b.nummer))
                            .map((lager) => (
                                <MenuItem value={lager.nummer} key={lager.nummer}>
                                    {lager.nummer}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid2>
            <Grid2 size={4} sx={{ padding: "4px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                    <DatePicker
                        formatDensity="dense"
                        timezone="Europe/Berlin"
                        label="Bestelldatum"
                        slotProps={{
                            field: {
                                clearable: true,
                                onClear: () => {
                                    setInternalFilter({ ...filter, orderDate: null });
                                },
                                sx: {
                                    ...{
                                        "& .MuiInputBase-input ": { padding: "8px" },
                                        "& .MuiInputLabel-formControl:not(.MuiInputLabel-shrink)": { padding: "0px", top: "-8px" },
                                    },
                                },
                            },
                        }}
                        value={filter.orderDate ? dayjs(filter.orderDate) : null}
                        onChange={(newValue) => {
                            const value = newValue ? newValue.toDate() : null;
                            setInternalFilter({ ...filter, orderDate: value });
                        }}
                    />
                </LocalizationProvider>
            </Grid2>
            <Grid2 size={4} sx={{ padding: "4px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                    <DatePicker
                        formatDensity="dense"
                        timezone="Europe/Berlin"
                        label="Fälligkeitsdatum"
                        slotProps={{
                            field: {
                                clearable: true,
                                onClear: () => {
                                    setInternalFilter({ ...filter, dueDate: null });
                                },
                                sx: {
                                    ...{
                                        "& .MuiInputBase-input ": { padding: "8px" },
                                        "& .MuiInputLabel-formControl:not(.MuiInputLabel-shrink)": { padding: "0px", top: "-8px" },
                                    },
                                },
                            },
                        }}
                        value={filter.dueDate ? dayjs(filter.dueDate) : null}
                        onChange={(newValue) => {
                            const value = newValue ? newValue.toDate() : null;
                            setInternalFilter({ ...filter, dueDate: value });
                        }}
                    />
                </LocalizationProvider>
            </Grid2>
            <Grid2 size={4} sx={{ padding: "4px" }}>
                {kommissionierernIsLoaded && <LinearProgress />}
                <FormControl sx={{ width: "100%" }} size="small">
                    <InputLabel disabled={!kommissioniererFilterAllowed()} id="bearbeiter-label">
                        Bearbeiter
                    </InputLabel>

                    <Select
                        labelId="bearbeiter-label"
                        id="bearbeiter"
                        value={filter.assignedTo[0]}
                        size="small"
                        disabled={!kommissioniererData || !kommissioniererFilterAllowed()}
                        multiple={false}
                        onChange={(v) => {
                            const value = v.target.value;
                            setInternalFilter({ ...filter, assignedTo: [value] });
                        }}
                        onClick={(v) => {
                            // in SingleSelect clicking the same element doesnt rest it
                            if ((v.target as HTMLOptionElement).selected) {
                                setInternalFilter({ ...filter, assignedTo: [""] });
                            }
                        }}
                    >
                        <MenuItem value={0} key="NichtZugewiesen">
                            Nicht zugewiesen
                        </MenuItem>
                        {kommissioniererData
                            ?.toSorted((a, b) => a.name.localeCompare(b.name))
                            .map((kommissionierer) => (
                                <MenuItem value={kommissionierer.userId} key={kommissionierer.userId}>
                                    {kommissionierer.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid2>
        </Grid2>
    );
}
