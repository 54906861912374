import { Alert, AlertTitle } from "@mui/material";

type SuccessDisplayProps = {
    title: string;
    message: string;
};
export default function SuccessDisplay(props: Readonly<SuccessDisplayProps>) {
    const { title, message } = props;
    return (
        <Alert severity="success">
            <AlertTitle>{title}</AlertTitle>
            {message}
        </Alert>
    );
}
