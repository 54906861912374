import { LinearProgress, TextField, Button, Box } from "@mui/material";
import { GridColDef, GridRowParams, DataGridPro } from "@mui/x-data-grid-pro";
import useGetDynamicPath from "applications/hooks/useGetDynamicPath";
import useAuthentication from "authentication/useAuthentication";
import { AppContext } from "context/AppContext";
import { Artikel } from "Models/Artikel";
import { ArtikelWithPuffer } from "Models/ArtikelWithPuffer";
import { useContext, useState, useEffect, useMemo, useCallback } from "react";
import DeleteCartItem from "./DeleteCartItem";
import UpdateCartItem from "./UpdateCartItem";
import useWarenkorb from "./useWarenkorb";
import ErrorDisplay from "applications/components/ErrorDisplay";
import usePost from "applications/hooks/usePost";
import usePutById from "applications/hooks/usePutById";
import InfoDisplay from "applications/components/InfoDisplay";
import SuccessDisplay from "applications/components/SuccessDisplay";
import { CartItem } from "Models/CartItem";

export default function Warenkorb() {
    const { cartItemCount, cartLager } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [ordered, setOrdered] = useState<boolean>(false);

    if (cartItemCount === 0 || cartLager === undefined) {
        return (
            <div className="contentDiv">
                {ordered && <SuccessDisplay title="Bestellung" message="Die Bestellung wurde erfolgreich abgeschickt." />}
                <br />
                <InfoDisplay title="Warenkorb" message="Der Warenkorb ist leer." />
            </div>
        );
    }

    return (
        <div className="contentDiv">
            <h3>Lager: {cartLager?.name}</h3>

            <EditWarenkorb onBusy={(val) => setIsLoading(val)} />
            <br />
            <br />
            <FullfillWarenkorb
                disabled={isLoading}
                onOrdered={() => {
                    setOrdered(true);
                }}
            />
        </div>
    );
}

type EditWarenkorbProps = {
    onBusy: (val: boolean) => void;
};
function EditWarenkorb(props: Readonly<EditWarenkorbProps>) {
    const { onBusy } = props;

    const { firmaIdAnforderer } = useAuthentication();
    const { cartLager, setCartItemCount } = useContext(AppContext);
    const [entries, setEntries] = useState<CartItem[]>([]);

    const { warenkorb, isLoading: isLoadingWk, error: warenkorbError, triggerReload } = useWarenkorb();
    const { isLoading, data: artikel, error, load } = useGetDynamicPath<ArtikelWithPuffer>("/angelo/firma/{firma}/lager/{lager}/artikelzuweisung");

    const [editing, setEditing] = useState<boolean>(false);

    useEffect(() => {
        if (warenkorb) {
            let en = warenkorb.bestellpositionen ?? [];
            setEntries(en);
            setCartItemCount(en.length);
        }
    }, [warenkorb, setEntries, setCartItemCount]);

    // reload when all changed
    useEffect(() => {
        onBusy(isLoadingWk || isLoading || editing);
    }, [isLoadingWk, isLoading, editing, onBusy]);

    // Load artikelwithpuffer on start
    useEffect(() => {
        if (cartLager) {
            load([
                { key: "firma", value: firmaIdAnforderer },
                { key: "lager", value: cartLager.id.toString() },
            ]);
        }
    }, [cartLager, firmaIdAnforderer]);

    const bestellbareMengen = useMemo(() => {
        let mengePerArtikel: { [id: string]: number } = {};

        if (cartLager && artikel) {
            artikel.forEach((a) => {
                let matchingAuftrag = a.montageAuftraege.find((a) => {
                    //only montageauftraege of the currently selected lager is relevant
                    if (a.lager.id === cartLager.id) {
                        return true;
                    }
                    return false;
                });

                let auftragsMenge = 0;
                if (matchingAuftrag !== undefined) {
                    auftragsMenge = matchingAuftrag.anzahl;
                }

                let matchingLagerbestand = a.lagerBestaende.find((a) => {
                    //only bestand of the currently selected lager is relevant
                    if (a.lager.id === cartLager.id) {
                        return true;
                    }
                    return false;
                });

                let lagerMenge = 0;
                if (matchingLagerbestand !== undefined) {
                    lagerMenge = matchingLagerbestand.anzahl;
                }

                let allowedAmount = auftragsMenge - lagerMenge + a.puffer;
                let bestellbar = Math.max(0, allowedAmount);
                mengePerArtikel[a.id] = bestellbar;
            });
        }

        return mengePerArtikel;
    }, [artikel, cartLager]);

    const getMaxBestellMengeForArtikel = useCallback(
        (a?: Artikel) => {
            if (!a) {
                return 0;
            }
            if (bestellbareMengen[a.id]) {
                return bestellbareMengen[a.id];
            }

            return undefined;
        },
        [bestellbareMengen]
    );

    const columns: GridColDef<CartItem>[] = [
        {
            field: " ",
            type: "custom",
            width: 120,
            headerAlign: "left",
            headerName: "Anzahl",
            renderCell: (p) => {
                let maxAmount = getMaxBestellMengeForArtikel(p.row.artikel) ?? 0;
                return <UpdateCartItem amount={p.row.anzahl} max={maxAmount} item={p.row} onChanged={() => triggerReload()} />;
            },
            flex: 1,
        },
        {
            field: "Maximale Bestellmenge",
            flex: 2,
            type: "custom",
            valueGetter: (p, row: CartItem) => {
                return getMaxBestellMengeForArtikel(row.artikel);
            },
        },
        {
            field: "funktionsklasse",
            headerName: "Funktionsklasse",
            flex: 2,
            valueGetter: (p, row: CartItem) => {
                return row.artikel.funktionsklasse;
            },
        },

        {
            field: "anzeigename",
            headerName: "Name",
            flex: 4,
            valueGetter: (p, row: CartItem) => {
                return row.artikel.name;
            },
        },
        {
            field: "artikelnummer",
            headerName: "Artikelnummer",
            flex: 3,
            valueGetter: (p, row: CartItem) => {
                return row.artikel.artikelnummer;
            },
        },
        {
            field: "actions",
            type: "actions",
            flex: 1,
            getActions: (params: GridRowParams) => [<DeleteCartItem id={params.id as string} key="delete" onDeleted={() => triggerReload()} />],
        },
    ];

    return (
        <>
            {error && <ErrorDisplay title="Ein Fehler beim laden der Artikel" error={error} />}
            {warenkorbError && <ErrorDisplay title="Fehler beim laden des Warenkorbs" error={warenkorbError} />}
            <Box sx={{ minHeight: "100px" }}>
                <DataGridPro
                    loading={isLoadingWk || isLoading}
                    autoHeight
                    density="compact"
                    getRowId={(r) => r.id}
                    rows={entries}
                    columns={columns}
                    hideFooter={true}
                    slots={{ loadingOverlay: LinearProgress as any }}
                />
            </Box>
            <br />
            <EditBemerkung
                value={warenkorb?.anfordererBemerkung ?? ""}
                onEditingChange={(val) => {
                    setEditing(val);
                }}
                onChanged={(val) => {
                    triggerReload();
                }}
            />
        </>
    );
}

type EditbemerkungRequest = {
    id: number;
    bemerkung: string;
};
type EditBemerkungProps = {
    value: string;
    onEditingChange: (val: boolean) => void;
    onChanged: (value: string) => void;
};
function EditBemerkung(props: Readonly<EditBemerkungProps>) {
    const { value, onChanged, onEditingChange } = props;
    const [editing, setEditing] = useState<boolean>(false);

    const [theValue, setTheValue] = useState<string>(value);

    const { error, isLoading, putObject } = usePutById<EditbemerkungRequest>("angelo/warenkorb/bemerkung");

    useEffect(() => {
        onEditingChange(editing);
    }, [editing]);

    useEffect(() => {
        setTheValue(value);
    }, [value]);

    const save = async () => {
        await putObject({ id: 0, bemerkung: theValue }); // id is not relevant here. Backend will use the warenkorb of the logged in user
        onChanged(theValue);
    };

    return (
        <>
            {error && <ErrorDisplay title="Fehler beim Speichern der Bemerkung" error={error} />}
            {isLoading && <LinearProgress />}
            <TextField
                disabled={!editing}
                multiline={true}
                rows={3}
                sx={{ width: "100%" }}
                onChange={(el) => {
                    setTheValue(el.target.value);
                }}
                value={theValue ?? ""}
                label="Bemerkung für diesen Abruf"
                placeholder="Bemerkung"
            />
            <br />
            {!editing && (
                <Button variant="outlined" size="small" sx={{ marginRight: 10 }} onClick={() => setEditing(true)}>
                    Bemerkung bearbeiten
                </Button>
            )}
            {editing && (
                <>
                    <Button
                        disabled={isLoading}
                        variant="outlined"
                        size="small"
                        sx={{ marginRight: 10 }}
                        onClick={async () => {
                            await save();
                            setEditing(false);
                        }}
                    >
                        Speichern
                    </Button>
                    <Button
                        disabled={isLoading}
                        variant="outlined"
                        size="small"
                        sx={{ marginRight: 10 }}
                        onClick={() => {
                            setEditing(false);
                            setTheValue(value);
                        }}
                    >
                        Abbrechen
                    </Button>
                </>
            )}
        </>
    );
}

type FullFillWarenkorbModel = {
    lagerId: number;
};

type FullfillWarenkorbProps = {
    disabled: boolean;
    onOrdered: () => void;
};
function FullfillWarenkorb(props: Readonly<FullfillWarenkorbProps>) {
    const { disabled, onOrdered } = props;

    const { error, isLoading, createObject } = usePost<FullFillWarenkorbModel>("angelo/warenkorb/wandlung");

    const { cartLager, setCartLager, setCartItemCount, amountOffeneAnforderungen, setAmountOffeneAnforderungen } = useContext(AppContext);

    return (
        <>
            {error && <ErrorDisplay title="Fehler beim Bestellen" error={error} />}
            {isLoading && <LinearProgress />}
            <Button
                disabled={disabled || isLoading}
                variant="outlined"
                size="small"
                onClick={async () => {
                    await createObject({ lagerId: cartLager?.id } as FullFillWarenkorbModel);
                    if (!error) {
                        setAmountOffeneAnforderungen(amountOffeneAnforderungen + 1);
                        setCartLager(undefined);
                        setCartItemCount(0);
                        onOrdered();
                    }
                }}
            >
                Bestellen
            </Button>
        </>
    );
}
