import { useContext, useEffect, useState } from "react";
import axios from "ApiClient";
import { AppContext } from "context/AppContext";
import useAuthentication from "authentication/useAuthentication";
import { UserRoles } from "Models/User";
import ShoppingCart from "Models/ShoppingCart";

export default function useWarenkorb() {
    const { setCartLager } = useContext(AppContext);
    const { isAuthenticated, hasRole } = useAuthentication();

    const [warenkorb, setWarenkorb] = useState<ShoppingCart | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [reload, setReload] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        if (isAuthenticated && hasRole(UserRoles.ANFORDERER)) {
            const loadWarenkorb = async () => {
                setIsLoading(true);
                setError("");
                try {
                    const response = await axios.get("/angelo/warenkorb");
                    setWarenkorb(response.data);
                    setCartLager(response.data.lager);
                } catch (error: any) {
                    setError(error.message);
                    setWarenkorb(undefined);
                    setCartLager(undefined);
                } finally {
                    setIsLoading(false);
                }
            };
            loadWarenkorb();
        }
    }, [reload, isAuthenticated]);

    const triggerReload = () => {
        setReload(!reload);
    };

    return {
        warenkorb,
        isLoading,
        error,
        triggerReload,
    };
}
