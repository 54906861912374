import { useState } from "react";
import axiosClient from "ApiClient";

export default function useDeleteById<T>(route: string) {
    const [data, setData] = useState<T | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const deleteObject = async (id: string) => {
        if (id) {
            setIsLoading(true);
            setError("");
            setData(undefined);
            await axiosClient
                .delete<T>(`/${route}/${id}`)
                .then((response) => {
                    setData(response.data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setError(error.message + " " + error.response?.data);
                    setIsLoading(false);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    return { data, isLoading, error, deleteObject };
}
