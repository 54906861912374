import { DataGridPro, GridColDef, GridSortModel } from "@mui/x-data-grid-pro";
import ErrorDisplay from "applications/components/ErrorDisplay";
import useGetFiltered, { SortItem } from "applications/hooks/useGetFiltered";
import Lieferschein, { LieferscheinStatus } from "Models/Lieferschein";
import EnumValueDisplay from "applications/components/EnumValueDisplay";
import { useEffect, useState } from "react";
import LieferungsTyp from "applications/components/LieferungsTyp";
import { Lager } from "Models/Lager";

const filterActiveLieferscheine = {
    status: [LieferscheinStatus.OFFEN, LieferscheinStatus.VERSANDBEREIT, LieferscheinStatus.BEAUFTRAGT],
};

const filterArchivedLieferscheine = {
    status: [LieferscheinStatus.ABGESCHLOSSEN],
};

type ListLieferscheineProps = {
    isHistory: boolean;
    ausgangslager: Lager | undefined;
    reloadCounter: number;
    onSelected: (id: string) => void;
};
export default function ListLieferscheine(props: Readonly<ListLieferscheineProps>) {
    const { isHistory, ausgangslager, reloadCounter, onSelected } = props;

    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 });
    const [sortItem, setSortItem] = useState<SortItem>();

    const initialFilter = isHistory ? filterArchivedLieferscheine : filterActiveLieferscheine;

    let url = "angelo/ruecklogistik/lieferscheine";

    if (ausgangslager) {
        url = `angelo/ruecklogistik/alager/${ausgangslager.id}/lieferscheine`;
    }
    const { data, isLoading, error, setPagination, setSort, load } = useGetFiltered<Lieferschein[]>(url, initialFilter);

    useEffect(() => {
        if (reloadCounter > 0 && !isLoading) {
            load();
        }
    }, [reloadCounter]);

    const columns: GridColDef[] = [
        { field: "lieferscheinNummer", headerName: "Lieferscheinnummer", width: 150 },
        { field: "status", headerName: "Status", width: 110, valueGetter: (val) => val, renderCell: (params) => <EnumValueDisplay value={params.value} /> },
        { field: "zielort", headerName: "Zielort", width: 100 },
        {
            field: "typ",
            headerName: "Typ",
            width: 120,
            valueGetter: (val) => val,
            renderCell: (params) => {
                return <LieferungsTyp typ={params.value} farbe={params.row.farbe} />;
            },
        },
        {
            field: "gebinde",
            headerName: "Gebinde",
            width: 120,
            valueGetter: (val) => val,
            renderCell: (params) => {
                return <EnumValueDisplay value={params.value} />;
            },
        },
    ];

    useEffect(() => {
        if (ausgangslager) {
            load();
        }
    }, [ausgangslager]);

    useEffect(() => {
        setPagination(paginationModel);
    }, [paginationModel]);

    useEffect(() => {
        setSort(sortItem);
    }, [sortItem]);

    return (
        <>
            {error && <ErrorDisplay title="Fehler beim Laden der Lieferscheine" error={error} />}
            <DataGridPro
                autoHeight={true}
                onRowClick={(params) => onSelected(params.id as string)}
                density="compact"
                columns={columns}
                rows={data.results}
                paginationMode="server"
                rowCount={data.count}
                pagination
                pageSizeOptions={[20, 50, 100]}
                paginationModel={paginationModel}
                onPaginationModelChange={(pagination) => {
                    setPaginationModel(pagination);
                }}
                sortingMode="server"
                onSortModelChange={(sortModel: GridSortModel) => {
                    if (sortModel[0]) {
                        setSortItem(sortModel[0]);
                    }
                }}
                loading={isLoading}
            />
        </>
    );
}
