import { Box, CircularProgress, MenuItem, Select } from "@mui/material";
import useGetDynamicPath from "applications/hooks/useGetDynamicPath";
import useAuthentication from "authentication/useAuthentication";
import { Lager } from "Models/Lager";
import { useEffect, useState } from "react";
import ErrorDisplay from "./ErrorDisplay";

type AusgangsLagerPickerProps = {
    onSelected: (lager: Lager) => void;
    selected?: Lager;
};
export function AusgangsLagerPicker(props: Readonly<AusgangsLagerPickerProps>) {
    const { selected, onSelected } = props;
    const { data, error, isLoading, load } = useGetDynamicPath<Lager>("/angelo/firmen/{firmaId}/lager");

    const { firmaIdAnforderer } = useAuthentication();

    useEffect(() => {
        if (firmaIdAnforderer && !isLoading) {
            let params = [{ key: "firmaId", value: firmaIdAnforderer }];
            load(params);
        }
    }, [firmaIdAnforderer]);

    return <LagerPicker selected={selected} onSelected={(lager) => onSelected(lager)} data={data} error={error} isLoading={isLoading} eingangslager={false} />;
}

type LagerPickerProps = {
    data: Lager[] | undefined;
    error: string;
    isLoading: boolean;
    eingangslager: boolean;
    selected?: Lager;
    onSelected: (lager: Lager) => void;
};
function LagerPicker(props: Readonly<LagerPickerProps>) {
    const { data, error, isLoading, eingangslager, selected, onSelected } = props;
    const [value, setValue] = useState<string>(selected ? selected.id.toString() : "");

    const lagername = eingangslager ? "nummer" : "aNummer";

    useEffect(() => {
        if (value) {
            let num = parseInt(value);
            let lager = data?.find((l) => l.id === num)!;
            if (lager) {
                onSelected(lager);
            }
        }
    }, [value]);

    return (
        <>
            {error && <ErrorDisplay error={error} title="Fehler beim laden der Lager." />}
            <Box sx={{ display: "flex" }}>
                <Box>{eingangslager ? "Eingangslager" : "Ausgangslager"} wählen:</Box>
                {!data && selected && <Box sx={{ paddingLeft: 1 }}>{selected[lagername]}</Box>}
                <Box sx={{ paddingLeft: 1 }}>
                    {data && (
                        <Select variant="standard" size="small" value={value} onChange={(evt) => setValue(evt.target.value)}>
                            {data.map((l) => (
                                <MenuItem key={l.id} value={l.id}>
                                    {l[lagername]} - {l.name}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </Box>
                <Box>{isLoading && <CircularProgress size={18} />}</Box>
            </Box>
        </>
    );
}
