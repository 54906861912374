import { IconButton, Tooltip } from "@mui/material";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import useDeleteById from "applications/hooks/useDeleteById";
import { ErrorDialog } from "applications/components/ErrorDialog";

type DeleteCartItemProps = {
    id: string;
    onDeleted: () => void;
};
export default function DeleteCartItem(props: Readonly<DeleteCartItemProps>) {
    const { id, onDeleted } = props;

    const { isLoading, error, deleteObject } = useDeleteById("angelo/warenkorb/bestellposition");

    const deleteItem = async () => {
        if (!isLoading) {
            await deleteObject(id);
            onDeleted();
        }
    };

    return (
        <IconButton aria-label="delete" disabled={isLoading}>
            <Tooltip title={error || "Artikel löschen"}>
                <RemoveShoppingCartIcon color={error ? "error" : "inherit"} fontSize="small" onClick={deleteItem} />
            </Tooltip>
            {error && <ErrorDialog error={error} title={"Fehler beim Entfernen des Artikels"} />}
        </IconButton>
    );
}
