import { Box, Select, MenuItem, CircularProgress } from "@mui/material";
import EnumValueDisplay from "applications/components/EnumValueDisplay";
import ErrorDisplay from "applications/components/ErrorDisplay";
import usePutById from "applications/hooks/usePutById";
import Lieferschein, { LieferscheinStatus } from "Models/Lieferschein";
import { useEffect, useState } from "react";

type UpdateLieferscheinStatus = {
    id: string;
    status: string;
};

type SelectLieferscheinStatusProps = {
    lieferschein: Lieferschein;
    allowedBearbeitungsStatus: LieferscheinStatus[];
    onChange: (status: string) => void;
};
export function SelectLieferscheinStatus(props: Readonly<SelectLieferscheinStatusProps>) {
    const { lieferschein, allowedBearbeitungsStatus, onChange } = props;
    const [status, setStatus] = useState<string>(lieferschein.status);

    const { data, isLoading, error, putObject } = usePutById<UpdateLieferscheinStatus>("angelo/ruecklogistik/lieferscheine/{id}/status");

    const changeStatus = async (newStatus: string) => {
        if (status !== newStatus) {
            const payload = { id: lieferschein.id, status: newStatus };
            await putObject(payload);
            setStatus(newStatus);
        }
    };

    useEffect(() => {
        if (data) {
            onChange(data.status);
        }
    }, [data]);

    return (
        <>
            <Box sx={{ display: "flex" }}>
                <Select disabled={isLoading} variant="standard" size="small" value={status} onChange={(evt) => changeStatus(evt.target.value)}>
                    {allowedBearbeitungsStatus.map((bs) => (
                        <MenuItem key={bs.toString()} value={bs}>
                            <EnumValueDisplay value={bs} />
                        </MenuItem>
                    ))}
                </Select>
                {isLoading && <CircularProgress size={"25px"} />}
            </Box>
            {error && <ErrorDisplay title="Fehler beim speichern des Lieferescheins" error={error} />}
        </>
    );
}
