import { Button } from "@mui/material";
import useAuthentication from "authentication/useAuthentication";
import Header from "layout/Header";
import Navigation from "layout/Navigation";
import { UserRoles } from "Models/User";
import { useMatch } from "react-router-dom";

type SecuredPageProps = {
    children: JSX.Element;
};

function SecuredPageLogistik(props: Readonly<SecuredPageProps>) {
    const { children } = props;
    const { isAuthenticated, hasRole, logout } = useAuthentication();
    const hideHeaders = useMatch("/logistik/lieferschein/*");

    if (!isAuthenticated || !hasRole(UserRoles.KOMMISIONIERER, UserRoles.KOORDINATOR)) {
        return (
            <>
                <Header />
                <Navigation />
                <div className="contentDiv">
                    Die von dir aufgerufene Seite ist nur für Logistikmitarbeiter (Kommissionierer) zugänglich!
                    <br /> Du bist nicht authorisiert diese Seite zu betreten!
                    <p>Bitte stelle sicher, dass du über die benötigte Rolle verfügst.</p>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            logout();
                        }}
                    >
                        Neu anmelden für Gerätelogistik
                    </Button>
                </div>
            </>
        );
    } else {
        return (
            <>
                {!hideHeaders && (
                    <>
                        <Header />
                        <Navigation />
                    </>
                )}
                {children}
            </>
        );
    }
}
export default SecuredPageLogistik;
