import { Firma } from "./Firma";
import { LagerBestand } from "./LagerBestand";

export type Lager = {
    id: number;
    name: string;
    nummer: string;
    aNummer: string;
    anforderer: Firma;
    lagerbestand: LagerBestand[];
};

export const LAGERNUMMER_RUECKLOGISTIK = "lager-ruecklogistik";
export const LAGERNUMMER_ZENTRALLAGER = "lager-zentral";
export const LAGERNUMMER_QS = "lager-qs";

export const LAGER_RUECKLOGISTIK: Lager = { nummer: LAGERNUMMER_RUECKLOGISTIK } as Lager;
export const LAGER_ZENTRALLAGER: Lager = { nummer: LAGERNUMMER_ZENTRALLAGER } as Lager;
