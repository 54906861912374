import usePost from "applications/hooks/usePost";
import { Artikel } from "Models/Artikel";
import { useEffect, useState } from "react";
import { Box, CircularProgress, IconButton, TextField, Tooltip } from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { ErrorDialog } from "applications/components/ErrorDialog";

type AddCartItemModel = {
    artikelNummer: string;
    anzahl: number;
};

type AddCartItemProps = {
    artikel: Artikel;
    max: number;
    amount: number;
    onChanged: (val: number) => void;
};
export default function AddCartItem(props: Readonly<AddCartItemProps>) {
    const { max, amount, artikel, onChanged } = props;
    const [value, setValue] = useState<number>(amount);

    const { data, error, isLoading, createObject } = usePost<AddCartItemModel>("angelo/warenkorb/bestellposition/");

    const valueChanged = amount !== value;

    const save = async () => {
        if (valueChanged && !isNaN(value)) {
            await createObject({
                artikelNummer: artikel.artikelnummer,
                anzahl: value,
            });
        }
    };

    useEffect(() => {
        if (data) {
            onChanged(value);
        }
    }, [data]);

    return (
        <Box>
            <TextField
                sx={{ width: "50px" }}
                type="number"
                variant="standard"
                value={value}
                size="small"
                onChange={(evt) => {
                    let val = parseInt(evt.target.value);
                    if (val <= 0) {
                        setValue(0);
                    } else if (val > max) {
                        setValue(max);
                    } else {
                        setValue(val);
                    }
                }}
                onBlur={() => {
                    save();
                }}
            />
            {isLoading && <CircularProgress size={18} sx={{ marginLeft: 1, marginTop: 1 }} />}
            {!isLoading && (
                <IconButton
                    disabled={!valueChanged}
                    onClick={() => {
                        //save(); // we already save onBlur
                    }}
                >
                    <Box sx={{ fontSize: "18px" }}>
                        <Tooltip title={error || "In den Warenkorb legen"}>
                            <AddShoppingCartIcon color={error ? "error" : "inherit"} />
                        </Tooltip>
                        {amount > 0 ? amount : ""}
                    </Box>
                </IconButton>
            )}
            {error && <ErrorDialog error={error} title={"Fehler beim Speichern von Warenkorb"} />}
        </Box>
    );
}
