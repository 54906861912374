import { Navigate, Route, Routes } from "react-router-dom";
import LieferscheinHistorie from "./historie/LieferscheinHistorie";
import GeraetScannen from "./geraete/GeraetScannen";
import LieferscheineView from "./lieferscheine/LieferscheineView";

export default function RoutesRuecklogistik() {
    return (
        <Routes>
            <Route path={"/lieferscheine"} element={<LieferscheineView />} />
            <Route path={"/lieferscheine/:id"} element={<LieferscheineView />} />
            <Route path={"/lieferscheinhistorie"} element={<LieferscheinHistorie />} />
            <Route path={"/scan"} element={<GeraetScannen />} />

            <Route path="/*" element={<Navigate to={"lieferscheine"} />} />
        </Routes>
    );
}
